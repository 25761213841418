import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import {
	Link,
	Typography,
	Paper,
	Grid,
	Tabs,
	Tab,
	Icon,
	GridList,
	GridListTile,
	Box,
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Toolbar,
	Checkbox,
	IconButton,
	Tooltip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
	Slider,
	Slide
	
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import LabelIcon from '@material-ui/icons/Label';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import i18n from './../i18n.js';


import './index.css';
import API from './../api.js';
import LanguageSelector from './../LanguageSelector.js'

import Cover from './Cover.jsx';


const PrettoSlider = withStyles({
  root: {
    
    height: 20,
  },
  disabled:{
  	"& .MuiSlider-thumb": {
	  height: 70,
	  width: 70,
	  border: '2px solid currentColor',
	    marginTop: -20,
	    marginLeft: 'calc(0% - (2.8% * 2.5)  )',
	    paddingTop:10,
	}
  },
  thumb: {
  	
    height: 70,
    width: 70,
    // backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -20,
    marginLeft:'calc(0% - (3% * 2.5)  )',
    paddingTop:10,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
    '& .bar': {
      // display: inline-block !important;
      height: 60,
      width: 0,
      backgroundColor: 'currentColor',


    },
    '& .text': {
      // display: inline-block !important;
      height: 0,
      width: 50,
      fontSize: 11,
      textAlign:'center'
      
    },

  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 20px)',
  },
  track: {
    height: 30,
    borderRadius: 4,

  },
  rail: {
    height: 30,
    borderRadius: 4,

  },
})(Slider);


function OngoingThumbComponent(props) {
  return (
    <span {...props}>
    	<div className="bar">
    		<img src={process.env.PUBLIC_URL + "/images/fokus_proyek/u943.svg"} alt="" style={{paddingLeft:"1vw"}} />
    	</div>
      <p className="text" style={{color:'white'}}>Ongoing</p>
     
    </span>
  );
}


function DoneThumbComponent(props) {
  return (
    <span {...props} >
    	<div className="bar">
    		<img src={process.env.PUBLIC_URL + "/images/fokus_proyek/done_u946.svg"} alt="" style={{paddingLeft:"1vw", paddingTop:"1vh"}} />
    	</div>
      <p className="text" style={{color:'white'}}>Done</p>
     
    </span>
  );
}


const useStyles = {
	root : {
		width: '100%',
		height: '100%'
	},
	container: {
		minHeight: "20vh",
		paddingTop:"10vh",
		paddingBottom: "10vh",
		paddingLeft: "17vh",
		paddingRight: "18vh",
		"@media (max-width: 768px)" : {
			minWidth: "1300px"
		},
		"@media (max-width: 600px)" : {
			minWidth: "1300px",
			paddingLeft:"8vw"
		},
		"@media (min-width: 1444px)" : {
			paddingLeft: "0vh",
			paddingRight: "0vh",
			paddingBottom: "2vh",
			paddingTop: "2vh"
		}
	},
	paper : {
		width: '100%',
		marginBottom: "2vh"
	},
	table : {
		minWidth : 750
	},
	visuallyHidden : {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1
	},
	containerGallery:{
		width:"10vw", 
		height:"auto",
		"@media (max-width: 768px)" : {
			width: "100vw",	
		},
		"@media (max-width: 600px)" : {
			width: "100vw",	
		}
	},
	imgLabelKomponen:{
		width:"20vw", 
		height:"auto",

		"@media (max-width: 600px)" : {
			width: "40vw",	
		}
	},
	breadcrumbContainer: {
		marginTop:"5vh", 
		marginBottom:"5vh",
		"@media (max-width: 600px)" : {
			marginTop:"1vh", 
			marginBottom:"1vh",
		},
		"@media (min-width: 1444px)" : {
			marginTop:"3vh", 
			marginBottom:"3vh",
		}
	},

	
};

class Fokus extends React.Component{

	constructor(props){
		super(props)
		this.state = {
			breadcrumb: "Fokus Proyek",
			breadcrumb_ina: "Fokus Proyek",
			breadcrumb_en : "Project Focus",
			data: [],
			data_ina: [],
			data_en: [],
			data_komponen: [],
			limit : 5,
			filterCari: "",
			header1:"fokus_header_active",
			header2:"",
			header3:"",
			header4: "",
			komponenLabel: "u685.svg",
			textLabel: "KOMPONEN 1",
			tahun: {
				2018: "",
				2019: "",
				2020: "",
				2021: "tahun_active",
				2022: "",
				2023: "",
				2024: "",
			},
			componentColor: "fokus_component1",
			sliderThumb: OngoingThumbComponent,
			sliderColor: "rgba(255, 221, 0, 1)",
			editButton: "edit",
			disabled: "true",
			indikatorForm: {
				id: "",
				indikator: "",
				tahun: ""
			},
		}

		this.handleSliderThumb = this.handleSliderThumb.bind(this)
		this.handleHeader = this.handleHeader.bind(this);
	}

	componentDidMount(){
		
		API.get('/fokus')
			.then(res=> {
				var apires = res.data.response;
				const data= [];
				const data_ina = [[],[],[],[]];
				const data_en = [[],[],[],[]];

				var indikatorForm = this.state.indikatorForm;
				indikatorForm = {
					id: apires.data.indikator.id,
					indikator: apires.data.indikator.indikator,
					tahun: apires.data.indikator.tahun
				}

				apires.data.komponen.forEach(item => {

					if(item.detail_ina == null){
						item.detail_ina = ""
					}

					if(item.detail_en == null){
						item.detail_en = ""
					}

					data_ina[item.komponen - 1].push({
						id: item.id,
						komponen: item.komponen,
						tahun: item.tahun,
						detail: item.detail_ina,
						goal: item.goal_ina,
						progres: item.progres,
						status: item.status
					})
					data_en[item.komponen - 1].push({
						id: item.id,
						komponen: item.komponen,
						tahun: item.tahun,
						detail: item.detail_en,
						goal: item.goal_en,
						progres: item.progres,
						status: item.status
					})
				})

				this.setState({indikatorForm})
				this.setState({
					data: data_ina,
					data_ina: data_ina,
					data_en: data_en,
					data_komponen: data_ina[0]
				})

			})
			.catch((error)=>{
				var err = error.response;
			
				
				
			})
	}


	static getDerivedStateFromProps(nextProps, prevState){

		var x = prevState.data_komponen[0]
		var data_komponen = prevState.data_komponen
		

		if(nextProps.i18n.language == "ina"){
			if(x){
				data_komponen = prevState.data_ina[x.komponen - 1]
			}
			return {
				data: prevState.data_ina,
				data_komponen: data_komponen

				
			}
		}else{
			if(x){
				data_komponen = prevState.data_en[x.komponen - 1]
			}
			return {
				data: prevState.data_en,
				data_komponen: data_komponen
				
			}
		}
	}


	handleHeader(e){

		this.setState({
			header1:"",
			header2:"",
			header3:"",
			header4:"",
			data_komponen: ""
		});
		var sliderColor = "";
		var componentColor = "";
		var data_komponen;
		var komponenLabel;
		var textLabel;
		if(e.target.id == "header1"){
			sliderColor = "rgba(255, 221, 0, 1)"
			componentColor = "fokus_component1"
			data_komponen = this.state.data[0]
			komponenLabel = "u685.svg"
			textLabel = "KOMPONEN 1"
		}
		else if(e.target.id == "header2"){
			sliderColor = "rgba(0, 187, 112, 1)"
			componentColor = "fokus_component2"
			data_komponen = this.state.data[1]
			komponenLabel = "u766.svg"
			textLabel = "KOMPONEN 2"
		}
		else if(e.target.id == "header3"){
			sliderColor = "rgba(162, 59, 255, 1)"
			componentColor = "fokus_component3"
			data_komponen = this.state.data[2]
			komponenLabel = "u803.svg"
			textLabel = "KOMPONEN 3"
		}
		else if(e.target.id == "header4"){
			sliderColor = "rgba(255, 80, 80, 1)"
			componentColor = "fokus_component4"
			data_komponen = this.state.data[3]
			komponenLabel = "u851.svg"
			textLabel = "KOMPONEN 4"
		}

		this.setState({
			[e.target.id]: "fokus_header_active",
			sliderColor: sliderColor,
			componentColor: componentColor,
			data_komponen: data_komponen,
			komponenLabel: komponenLabel,
			textLabel: textLabel,
			disabled: "true",
			editButton: "edit"
		})



	}

	handleSliderThumb(e){

		var input_name = e.target.previousSibling.name;
		if(parseInt(input_name.substr(input_name.length - 2)) > 10){
			var last_char = input_name.substr(input_name.length - 2);
		}else{
			var last_char = input_name.substr(input_name.length - 1);
		}
		var target_name = input_name.replace(last_char, "")
		var index = parseInt(last_char);

		const byProp = (prop,key) => ({[prop]:k}) => k == key
		var data = this.state.data_komponen.find(byProp('id', index))


		if(data[target_name] == "done"){
			data[target_name] = "ongoing"
		}else{
			data[target_name] = "done"
		}
		
		this.setState({data})	

	}

	

	render(){

		const { classes } = this.props;

		return(
			<div>
				<Grid  className="breadcrumb-container"   >
					
					<Grid item md sm xs >
						<Breadcrumbs aria-label="breadcrumb">
						 	<a className="text-color-default" href={global.url+"/beranda"} style={{textDecoration:"none"}} >KalFor</a> | 
						 	<Typography className="text-color-green">{this.state.breadcrumb}</Typography>
						 </Breadcrumbs>
					</Grid>
				</Grid>
				
				<Cover menu_name={this.state.breadcrumb} />
				<Container className={classes.container}>
					<Grid container spacing={3} alignItems="stretch">
						<Grid item lg={12} md={12} sm={12} xs={12} >
							<Paper elevation={0}  style={{ borderRadius:"1rem" }}>
				  				
								<Grid container spacing={3}>
									<Grid item lg={3} md={3} sm={3} xs={3} >
										<div>
											<h3 id="header1" className={"fokus_header " + this.state.header1} onClick={this.handleHeader} >{ i18n.t('fokus_komponen1.label')}</h3>
										</div>
										
									</Grid>
									<Grid item lg={3} md={3} sm={3} xs={3}  >
										<div>
											<h3 id="header2"  className={"fokus_header " + this.state.header2} onClick={this.handleHeader} >{ i18n.t('fokus_komponen2.label')}</h3>
										</div>
									</Grid>
									<Grid item lg={3} md={3} sm={3} xs={3}  >
										<div>
											<h3 id="header3" className={"fokus_header " + this.state.header3} onClick={this.handleHeader} >{ i18n.t('fokus_komponen3.label')}</h3>
										</div>
									</Grid>
									<Grid item lg={3} md={3} sm={3} xs={3}  >
										<div>
											<h3 id="header4"  className={"fokus_header " + this.state.header4} onClick={this.handleHeader} >{ i18n.t('fokus_komponen4.label')}</h3>
										</div>
									</Grid>
								</Grid>
 
								<Grid item lg={12} md={12} sm={12} xs={12}  >
									<div>
										<div>
											<div className={"container-gallery " + classes.containerGallery}>
												<img className={classes.imgLabelKomponen} src={process.env.PUBLIC_URL + "/images/fokus_proyek/"+this.state.komponenLabel} alt="" />
										
												<div className="image-fokus-overlay">
													<h3>{this.state.textLabel}</h3>
												</div>
											</div>
										</div>
										
										<Grid container spacing={3}  alignItems="center">
											<Grid item lg={3} md={3} sm={3} xs={3}  >
												<form  noValidate autoComplete="off" id="indikatorForm"   action="/indikator/post" method="POST" onSubmit={this.handleSubmit}>
													<h2 style={{marginBottom:"0px"}} >{ i18n.t('fokus_indikator.label')}</h2>
													<div><i>{this.state.indikatorForm.indikator}</i></div>
													
												</form>
											</Grid>
											<Grid item lg={7} md={7} sm={7} xs={7}  >
												<Grid container spacing={1}>
													{
														Object.keys(this.state.tahun).map((item, index) => {
															

															if(this.state.indikatorForm.tahun == item){
																return(
																	<Grid item md>
																		<div className={"tahun-button "+this.state.tahun[item]+" "+this.state.componentColor}  >
																			<div className="tahun_text">{item}</div>
																		</div>
																		
																	</Grid>
																);
															}else{
																return(
																	<Grid item md>
																		<div className={"tahun-button " } >
																			<div className="tahun_text">{item}</div>
																		</div>
																		
																	</Grid>
																);
															}

															
														})
													}
												</Grid>
											</Grid>
											<Grid item lg={2} md={2} sm={2} xs={2}  >
												<h3  style={{marginBottom:"0px"}} >End of Project</h3>
												<p style={{margin:"0px"}} >Target 2024</p>
											</Grid>
										</Grid>
										
										{
											this.state.data_komponen.map((item, index) => {

												if(item.status == "done"){
													var thumbComopnent = DoneThumbComponent
												}else{
													var thumbComopnent = OngoingThumbComponent
												}


												return(

													<Grid container spacing={3} alignItems="center">
														<Grid item lg={3} md={3} sm={3} xs={3}  >
															<Grid container alignItems="center">
																<Grid item md={2} sm={2} xs={2}  >
																	<h1>{index + 1}</h1>
																</Grid>
																<Grid item md={10} sm={10} xs={10}  > 
																	<p>{item.detail}</p>
																</Grid>
															</Grid>

														</Grid>
														<Grid item lg={7} md={7} sm={7} xs={7}  >
															<div>
																
																<div>{item.progres} %</div>
																
																<PrettoSlider ThumbComponent={thumbComopnent} valueLabelDisplay="auto" aria-label="pretto slider" name={"status"+item.id} value={this.state.indikatorForm.tahun} min={2018} max={2024} step={1} style={{color:this.state.sliderColor}}  disabled={this.state.disabled} />
																
																
															</div>
															
														</Grid>
														<Grid item lg={2} md={2} sm={2} xs={2}  >
															<b>{item.goal}</b>
														</Grid>
													</Grid>



												)
											})
										}

									</div>
								</Grid>
								
							</Paper>
						</Grid>
						
					</Grid>

						
					
				</Container>
			</div>
		)
	}


}


export default withTranslation()(withStyles(useStyles)(Fokus));