import React from 'react'
import i18n from './i18n.js';
import  { withTranslation } from 'react-i18next'
import './index.css';

class LanguageSelector extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			value: ''
		}
		this.handleChange = this.handleChange.bind(this);
		
	}

	static getDerivedStateFromProps(nextProps, prevState){

		if(nextProps.i18n.language == "ina"){
			return {
				value: "ina"
			}
		}else{
			return {
				value: "en"
			}
		}
	}

	handleChange(event){
		this.setState({value:event.target.value});
		// console.log(i18n);
		i18n.changeLanguage(event.target.value);
	}

	render(){
		
		return(
			<select className="lng-select" value={this.state.value}  name="language" onChange={this.handleChange} >
				<option  value="ina" defaultValue >Indonesia</option>
				<option value="en" >English</option>
			</select>
		)
	}

	

}

export default withTranslation()(LanguageSelector);