import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import i18n from './../i18n.js';
import  { withTranslation } from 'react-i18next'


import HeaderComp from './Header.jsx';
import HeaderMobile from './HeaderMobile.jsx'
import BodyComp from './Body.jsx';
import FooterComp from './Footer.jsx';
import FooterMobile from './FooterMobile.jsx';




class WebLayout extends React.Component{

	constructor(props){
		super(props)
		this.state = { screenWidth: null };

		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

		
	}

	componentDidMount() {
		if(localStorage.getItem('lang')){
			i18n.changeLanguage(localStorage.getItem('lang'));
		}else{
			i18n.changeLanguage("ina");
		}
	    window.addEventListener("resize", this.updateWindowDimensions());
	}

	componentWillUnmount() {
	    window.removeEventListener("resize", this.updateWindowDimensions)
	}

	updateWindowDimensions() {
	   this.setState({ screenWidth: window.innerWidth });
	}

	render(){

		var msg = "Web Page";
		var header = this.state.screenWidth <= 768 ? <HeaderMobile /> : <HeaderComp />
		var footer = this.state.screenWidth <= 600 ? <FooterMobile /> : <FooterComp />
		return ( 		
			
			<body>
				<Paper  >
					<AppBar position="sticky">
						{header}
					</AppBar>
					
					{this.props.items}

					<div>
						{footer}
					</div>
				</Paper>
			</body>
		)
	}
	
}

export default WebLayout;