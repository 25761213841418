import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';	
import {
	Link,
	Typography,
	Paper,
	Grid,
	Tabs,
	Tab,
	Icon,
	GridList,
	GridListTile,
	Box,
	Button,
	TextField,
	InputAdornment
} from '@material-ui/core' 
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import SearchIcon from '@material-ui/icons/Search';
import { withTranslation } from 'react-i18next';
import i18n from './../i18n.js';


import './index.css';
import API from './../api.js';
import LanguageSelector from './../LanguageSelector.js'

import Cover from './Cover.jsx';



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}



const useStyles = {
	root: {
	    backgroundColor: "white",
	    
	  },
	container: {
		minHeight: "20vh",
		paddingTop:"5vh",
		paddingBottom: "10vh",
		paddingLeft: "0vh",
		paddingRight: "0vh"
	},
	tabHeader:{
		color: "green",
		'&:active ': {
			fontWeight: 'italic'
		}
	},

	viewMore: {
		width: '100%',
		height: "10vh"
	},
	breadcrumbContainer: {
		marginTop:"5vh", 
		marginBottom:"5vh",
		"@media (max-width: 600px)" : {
			marginTop:"1vh", 
			marginBottom:"1vh",
		},
		"@media (min-width: 1444px)" : {
			marginTop:"3vh", 
			marginBottom:"3vh",
		}
	},
	map: {
		marginBottom: "5vh",
		"@media (min-width: 1444px)" : {
			marginBottom: "0vh"
		}
	},
	mapIframe: {
		width:"100%",
		height: "60vh",
		"@media (min-width: 1444px)" : {
			height: "40vh"
		}
	},
	mapIframe2: {
		width:"100%",
		height: "40vh",
		
	}
	
	
};

class Location extends React.Component{

	constructor(props){
		super(props)
		this.state = {
			breadcrumb: "Aktifitas Proyek",
			breadcrumb_ina: "Aktifitas Proyek",
			breadcrumb_en : "Project Activity",
			data: [],
			data_ina: [],
			data_en: [],
			peta: "",
			petaForm: {
				link_url: "",
			},
			limit : 5,
			filterCari: "",
			value: 0
		}

		this.handleLoadMore = this.handleLoadMore.bind(this)
		this.handleCari = this.handleCari.bind(this)
		this.handleChange = this.handleChange.bind(this)
	}

	componentDidMount(){
		
		API.get('/location-all')
			.then(res => {
				var apires = res.data.response;
				var peta = apires.data.peta;
				var lokasi = apires.data.location;
				var data =[];
				var data_ina = [];
				var data_en = [];
				var submitForm = [];

				lokasi.forEach(item => {

					if(item.description_ina == null){
						item.description_ina = "";
					}

					if(item.description_en == null){
						item.description_en = "";
					}

					if(item.name_ina == null){
						item.name_ina = ""
					}

					if(item.name_en == null){
						item.name_en = ""
					}

					data_ina.push({
						id: item.id,
						name: item.name_ina,
						description: item.description_ina,
						link_url: item.link_url
					})

					data_en.push({
						id: item.id,
						name: item.name_en,
						description: item.description_en,
						link_url: item.link_url
					})

				})
				
				this.setState({
					peta: peta.link_url,
					data: data_ina,
					data_ina: data_ina,
					data_en: data_en,
					submitForm: data_ina
				})

			})
			.catch((error)=>{
				var err = error.response;
		
			})
	}

	static getDerivedStateFromProps(nextProps, prevState){

		if(nextProps.i18n.language == "ina"){
			return {
				data: prevState.data_ina,
				breadcrumb: prevState.breadcrumb_ina			
			}
		}else{
			return {
				data: prevState.data_en,
				breadcrumb: prevState.breadcrumb_en
			}
		}
	}

	handleChange(e, newValue){
     
        this.setState({
          value: newValue
        })
    }

	handleCari(e){
		this.setState({filterCari: e.target.value})
	}

	handleLoadMore(){
		var data_length = this.state.data.length
		var remain_data = data_length - this.state.limit
		if(remain_data < 5){
			var load_more = remain_data
		}else{
			var load_more = 5;
		}

		this.setState({limit : this.state.limit + load_more})
	}


	render(){

		const { classes } = this.props;

		
		return(
			<div>
				<Grid  className={classes.breadcrumbContainer}  container>
					<Grid item md={1} sm={1}>
						
					</Grid>
					<Grid item md  sm >
						<Breadcrumbs aria-label="breadcrumb">
						 	<a className="text-color-default" href={global.url+"/beranda"} style={{textDecoration:"none"}} >KalFor</a> | 
						 	<Typography className="text-color-green">{this.state.breadcrumb}</Typography>
						 </Breadcrumbs>
					</Grid>
				</Grid>
				
				<div className={classes.map}>
										<iframe src={this.state.peta} className={classes.mapIframe}></iframe>
									</div>
				<Container className={classes.container}>
					<Grid container spacing={3}>
						<Grid item md={8} sm={12} xs={12} >
							<div className={classes.root}>
						      <AppBar position="static" color="default">
						        <Tabs
						          className={classes.tabHeader}
						          value={this.state.value} onChange={this.handleChange}
						          indicatorColor="primary"
						          // textColor="primary"
						          variant="fullWidth"
						          aria-label="full width tabs example"
						          
						        >
						         {
					                this.state.data.map((item, index)=> {
					                    return (
					                        <Tab label={item.name} {...a11yProps(index)} />
					                    )
					                    
					                })
					              }
						        </Tabs>
						      </AppBar>
						      <SwipeableViews
						        
						        index={this.state.value}
						        onChangeIndex={this.state.handleChange}
						      >
						      	{
					              this.state.data.map((item,index) => {
					                  return(
					                      <TabPanel value={this.state.value} index={index}>
					                      
					                          <div className="footer-address"  dangerouslySetInnerHTML={{ __html: item.description }}  ></div>
					                      </TabPanel>
					                  )
					              })
					            }

						      </SwipeableViews>
						    </div>
						</Grid>
						<Grid item md={4} sm={12} xs={12} >
							<iframe className={classes.mapIframe2} src={this.state.data && this.state.data[this.state.value] ? this.state.data[this.state.value].link_url : null} allowfullscreen="true" loading="lazy"  ></iframe>
						</Grid>
					</Grid>


				</Container>
			</div>
		)
	}


}


export default withTranslation()(withStyles(useStyles)(Location));