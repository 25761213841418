import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
	Link,
	Typography,
	Paper,
	Grid,
	Tabs,
	Tab,
	Icon,
	GridList,
	GridListTile,
	Box,
	Button
} from '@material-ui/core' 

import { withTranslation } from 'react-i18next';
import i18n from './../i18n.js';

import './index.css';
import API from './../api.js';
import LanguageSelector from './../LanguageSelector.js'

import BerandaAktifitas from './BerandaAktifitas.jsx';


class Body extends React.Component{

	constructor(props){
		super(props);
		this.state = {
			data: [],
			data_ina: [],
			data_en: []
		}
	}

	componentDidMount(){
		API.get('/beranda')
			.then( res => {
				var apires = res.data.response;
				const data_ina = {
					sekilas_title: apires.data.sekilas_title_ina ,
					sekilas_description: apires.data.sekilas_description_ina,
					video_url: apires.data.video_url,
					lokasi_name: apires.data.lokasi_name_ina,
					lokasi_icon: apires.data.lokasi_icon,
					lokasi_thumbnail: apires.data.lokasi_thumbnail,
					fokus_name: apires.data.fokus_name_ina,
					fokus_icon: apires.data.fokus_icon,
					fokus_thumbnail: apires.data.fokus_thumbnail,
					penerima_laki: apires.data.penerima_laki,
					penerima_perempuan: apires.data.penerima_perempuan,
					penerima_icon1: apires.data.penerima_icon1,
					penerima_icon2: apires.data.penerima_icon2,
					penerima_thumbnail: apires.data.penerima_thumbnail,
					total_kegiatan: apires.data.total_kegiatan,
					total_manfaat: apires.data.total_manfaat
				}

				const data_en = {
					sekilas_title: apires.data.sekilas_title_en ,
					sekilas_description: apires.data.sekilas_description_en,
					video_url: apires.data.video_url,
					lokasi_name: apires.data.lokasi_name_en,
					lokasi_icon: apires.data.lokasi_icon,
					lokasi_thumbnail: apires.data.lokasi_thumbnail,
					fokus_name: apires.data.fokus_name_en,
					fokus_icon: apires.data.fokus_icon,
					fokus_thumbnail: apires.data.fokus_thumbnail,
					penerima_laki: apires.data.penerima_laki,
					penerima_perempuan: apires.data.penerima_perempuan,
					penerima_icon1: apires.data.penerima_icon1,
					penerima_icon2: apires.data.penerima_icon2,
					penerima_thumbnail: apires.data.penerima_thumbnail,
					total_kegiatan: apires.data.total_kegiatan,
					total_manfaat: apires.data.total_manfaat
				}

				
				this.setState({
					data: data_ina,
					data_ina: data_ina,
					data_en: data_en
				})
			})
	}

	static getDerivedStateFromProps(nextProps, prevState){

		if(nextProps.i18n.language == "ina"){
			return {
				data: prevState.data_ina
			}
		}else{
			return {
				data: prevState.data_en
			}
		}
	}


	render(){
		return (
			<div style={{marginTop:"2vh"}}>
				
				<BerandaAktifitas />
				<Paper className="section grey-section"  elevation={0} square spacing={1}>
					<Grid container>
						<Grid item lg={5} md={5} sm={12} xs={12} className="beranda-tentang">
							<div  dangerouslySetInnerHTML={{ __html: this.state.data.sekilas_title }}>
							</div>
							<div dangerouslySetInnerHTML={{ __html: this.state.data.sekilas_description }}>
							</div>
						</Grid>
						<Grid item lg={7} md={7} sm={12} xs={12} className="beranda-video">
							<iframe 
									className="video-gallery"
									src={this.state.data.video_url }>
									</iframe> 
						</Grid> 
					</Grid>
				</Paper>
				<Paper className="section proyek-section"  elevation={0}>
					<Grid container spacing={2}>
						<Grid item lg={4} md={4} sm={12} xs={12} >
							<a href={global.url+"/location"}>
							<Paper elevation={0} className="section-card-lfp" style={{ borderRadius:"2rem" }} >
								
					            <Grid container alignItem="center">
					            	<Grid item lg={12} md={12} sm={12} xs={12} >
					            		<div className="proyek-container" style={{width:"100%"}}>
				            				<img className="overlay-img" src={global.url_be_storage_public+"/"+this.state.data.lokasi_thumbnail} />
				            				<div className="filter blue"></div>
				            				<div className="overlay ">
				            				</div>
				            				<div className="overlay-content">
			            						<img src={global.url_be_storage_public+"/"+this.state.data.lokasi_icon} />
			            						<h2>{this.state.data.lokasi_name }</h2>
			            					</div>
					            		</div>
					            		
					            	</Grid>
									
								</Grid>
								
							</Paper> 
							</a>
						</Grid>
						<Grid item lg={4}  md={4} sm={12} xs={12}>
							<a href={global.url+"/fokus"}>
							<Paper elevation={0} className="section-card-lfp" style={{ borderRadius:"2rem" }}>
								<Grid container>
					            	<Grid item lg={12} md={12} sm={12} xs={12} >
					            		<div className="proyek-container" style={{width:"100%"}}>
				            				<img className="overlay-img" src={global.url_be_storage_public+"/"+this.state.data.fokus_thumbnail} />
				            				<div className="filter gray"></div>
				            				<div className="overlay "></div>
				            				<div className="overlay-content">
			            						<img src={global.url_be_storage_public+"/"+this.state.data.fokus_icon} />
			            						<h2>{this.state.data.fokus_name }</h2>
			            					</div>
					            		</div>
					            	</Grid>
									
								</Grid>
							</Paper>
							</a>
						</Grid>
						<Grid item lg={4}  md={4} sm={12} xs={12} >
							<Paper elevation={0} className="section-card-lfp" style={{ borderRadius:"2rem" }}>
								<Grid container>
					            	<Grid item lg={12} md={12} sm={12} xs={12} >
					            		<div className="proyek-container" style={{width:"100%"}}>
				            				<img className="overlay-img" src={global.url_be_storage_public+"/"+this.state.data.penerima_thumbnail} />
				            				<div className="filter green"></div>
				            				<div className="overlay " style={{verticalAlign:"bottom", bottom:"50%", borderBottomRightRadius:"0px", borderBottomLeftRadius:"0px"}}></div>
				            				<div className="overlay-content1" >
			            						<img className="overlay-img-2" src={global.url_be_storage_public+"/"+this.state.data.penerima_icon1} />
			            						<h1 className="overlay-text">{this.state.data.penerima_perempuan}%</h1>
			            					</div>
			            					<div className="filter-2 white" style={{verticalAlign:"bottom", top:"50%"}}></div>
			            					<div className="overlay-content-2" >
			            						<img className="overlay-img-2" src={global.url_be_storage_public+"/"+this.state.data.penerima_icon2} />
			            						<h1 className="overlay-text">{this.state.data.penerima_laki}%</h1>
			            					</div>
					            		</div>
					            	</Grid>
									
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				</Paper>
				<Paper className="section grey-section proyek-section total-section"  elevation={0}>
					<Grid container alignItem="center"  >
						<Grid item lg={3} md={3} sm={12} xs={12} >
							<div className="penerima-section">
								<img src={process.env.PUBLIC_URL + "/images/u67.png"} alt=""   className="beranda-logo1" />
							</div>
							
						</Grid>
						<Grid item lg={3}  md={3} sm={12} xs={12} >
							<div className="penerima-section text-color-green">
								<h2 className="penerima-font">{this.state.data.total_kegiatan}</h2>
								<h3>TOTAL KEGIATAN</h3>
							</div>
							
						</Grid>
						<Grid item lg={3}  md={3} sm={12} xs={12} >
							<div className="penerima-section text-color-green">
								<h2 className="penerima-font">{this.state.data.total_manfaat}</h2>
								<h3>PENERIMA MANFAAT</h3>
							</div>
							
						</Grid>
						<Grid item lg={3}  md={3} sm={12} xs={12} >
							<div className="penerima-section" >
								
								<img className="beranda-logo" src={process.env.PUBLIC_URL + "/Logos all.png"} alt=""  />
								
								
							</div>
							
						</Grid>
					</Grid>
				</Paper>
				<Paper className="section logo-section proyek-section"  elevation={0}>
					<Grid container justify="center" >
						
						<Grid container item md={12} sm={12} xs={12}>
							<Grid item lg={3}   md={3} sm={2} xs={2} >
								<a href="https://www.menlhk.go.id"  target="_blank">
									<img className="beranda-logo2" src={process.env.PUBLIC_URL + "/images/u73.png"} alt=""    />
								</a>
							</Grid>
							<Grid item lg={2} md={2} sm={2} xs={2} >
								<a href="https://www.thegef.org" target="_blank">
									<img className="beranda-logo2" src={process.env.PUBLIC_URL + "/images/u76.png"} alt=""  />
								</a>
							</Grid>
							<Grid item lg={2} md={2} sm={2} xs={2} >
								<a href="https://www.id.undp.org" target="_blank">
									<img className="beranda-logo2"  src={process.env.PUBLIC_URL + "/images/u75.png"} alt=""   />
								</a>
							</Grid>
							<Grid item lg={3} md={3} sm={4} xs={4} >
								<a href="https://www.bappenas.go.id/id/" target="_blank">
									<img className="beranda-logo2" src={process.env.PUBLIC_URL + "/images/u81.png"} alt=""  />
								</a>
							</Grid>
							<Grid item lg={2} md={2} sm={2} xs={2} >
								<a href="https://www.kemenkeu.go.id" target="_blank">
									<img className="beranda-logo2" src={process.env.PUBLIC_URL + "/images/u84.png"} alt=""    />
								</a>
							</Grid>
						</Grid>
						
					</Grid>
				</Paper> 

			</div>
		);
	}
}


export default withTranslation()(Body);