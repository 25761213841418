import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';	
import {
	Link,
	Typography,
	Paper,
	Grid,
	Tabs,
	Tab,
	Icon,
	Menu,
	MenuItem,
	Button,
	Popper,
	ClickAwayListener,
	MenuList,
	ListItem,
	Grow
} from '@material-ui/core' 
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { withTranslation } from 'react-i18next';
import i18n from './../i18n.js';

import './index.css';
import API from './../api.js';
import LanguageSelector from './../LanguageSelector.js'


const useStyles ={
  
  drawer: {
    width: "100%",
     paddingLeft: "2vw"
   
  },
  drawerPaper: {
    width: "100%",
    color: "#8C8C8C"


  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingRight:"2vw",
    paddingTop:"3vw",
    paddingBottom:"3vw",
    paddingLeft: "2vw",
    
    justifyContent: 'flex-end',
  },
  menulist: {
  	paddingLeft: "5vw",
  	paddingRight: "2vw",
  },
  collapse: {
  		paddingLeft:"10vw"
  }
  
};



class HeaderMobile extends React.Component{

	constructor(props){
		super(props);
		this.state= {
			language : "",
			anchorE1: "",
			open: false,
			open2: false,
			data: [],
			data_ina: [],
			data_en: [],
			active_ina: "active",
			active_en : "",
			openDrawer: false
			
		}
		this.anchorRef = React.createRef();
		this.anchorRef2 = React.createRef();

		this.handleDrawerToggle = this.handleDrawerToggle.bind(this)
		this.handleDrawerClose = this.handleDrawerClose.bind(this)


		this.handleLanguage = this.handleLanguage.bind(this);
		this.handleMenuOpen = this.handleMenuOpen.bind(this)
		this.handleMenuClose = this.handleMenuClose.bind(this)
		this.handleToggle = this.handleToggle.bind(this)
		this.handleListKeyDown = this.handleListKeyDown.bind(this)


		this.handleMenuOpen2 = this.handleMenuOpen2.bind(this)
		this.handleMenuClose2 = this.handleMenuClose2.bind(this)
		this.handleToggle2 = this.handleToggle2.bind(this)
		this.handleListKeyDown2 = this.handleListKeyDown2.bind(this)
	}

	componentDidMount(){

		API.get('/managemen-pengetahuan')
			.then( res => {
				var apires = res.data.response;
				
				const data_ina = []
				const data_en = []

				

				apires.data.forEach( item => {

					if(item.name == null){
						item.name = ""
					}
					
					data_ina.push({
						id: item.id,
						name: item.name,
						url: item.url,
						thumbnail: item.thumbnail,
						date: item.created_at.substr(0,10)
					})
					data_en.push({
						id: item.id,
						name: item.name,
						url: item.url,
						thumbnail: item.thumbnail,
						date: item.created_at.substr(0,10)
					})
				})

				this.setState({
					data: data_ina,
					data_ina: data_ina,
					data_en: data_en,
				})


			})
			.catch((error)=>{
				var err = error.response;

				
				
			})
	}

	static getDerivedStateFromProps(nextProps, prevState){

		if(nextProps.i18n.language == "ina"){
			return {
				data: prevState.data_ina,
				active_ina: "active",
				active_en: ""
			}
		}else{
			return {
				data: prevState.data_en,
				active_ina: "",
				active_en: "active"
			}
		}
	}

	handleLanguage(e){
		e.preventDefault();
		var pathname = e.target.pathname;
		var lang = pathname.replace("/fe/", "");
 		

		i18n.changeLanguage(lang.replace("/", ""))
		localStorage.setItem('lang', lang.replace("/", ""));

		if(lang === "en"){
			document.getElementById('en_lang').classList.add("active");
			document.getElementById('ina_lang').classList.remove("active");
		}else{
			document.getElementById('en_lang').classList.remove("active");
			document.getElementById('ina_lang').classList.add("active");
		}
	}

	handleDrawerToggle(){
		this.setState({openDrawer: !this.state.openDrawer})
	}

	handleDrawerClose(){
		this.setState({openDrawer: false})
	}


	handleMenuOpen(e){
		console.log(e)
		this.setState({anchorE1: e.currentTarget})
		
	}

	handleMenuClose(e){
		this.setState({open: false})
	}

	handleToggle(){
		if(this.state.open == true){
			this.setState({open: false})
		}else{
			this.setState({open: true})
		}
		
	}

	handleListKeyDown(e){
		if (e.key === 'Tab') {
	      e.preventDefault();
	      this.setState({open: false})
	    }
	}

	handleMenuOpen2(e){
		console.log(e)
		this.setState({anchorE1: e.currentTarget})
		
	}

	handleMenuClose2(e){
		this.setState({open2: false})
	}

	handleToggle2(){
		if(this.state.open2 == true){
			this.setState({open2: false})
		}else{
			this.setState({open2: true})
		}
		
	}

	handleListKeyDown2(e){
		if (e.key === 'Tab') {
	      e.preventDefault();
	      this.setState({open2: false})
	    }
	}


	render(){

		const { classes } = this.props;

		return(
			<div>
				<Paper elevation={3} square>
					
					<Grid container className="header-menu">
						<Grid container item md={6} sm={6} xs={6} alignItems="center">
							<Grid item md={1} sm={1} xs={1} ></Grid>
							<Grid item md={6} sm={10} xs={10} >
								<Link href={global.url+"/beranda"} >
									<img src={process.env.PUBLIC_URL + "/images/u2.png"} alt=""   style={{width:"80%"}} />
								</Link>
							</Grid>
							
						</Grid>

						<Grid container item md={6} sm={6} xs={6} justify="flex-end"  alignItems="flex-start" >
						
							<IconButton onClick={this.handleDrawerToggle} >
								<MenuIcon fontSize="large"  />
							</IconButton>
							
						</Grid>
					</Grid>
				</Paper>

				<Drawer className={classes.drawer} variant="persistent" anchor="left" open={this.state.openDrawer} classes={{paper: classes.drawerPaper}} >
						<div className={classes.drawerHeader}  >
							<Link href={global.url+"/beranda"} >
								<img src={process.env.PUBLIC_URL + "/images/u2.png"} alt=""   style={{width:"80%"}} />
							</Link>
							<Grid container justify="flex-end">
								<IconButton onClick={this.handleDrawerClose}>
					            	<ChevronLeftIcon fontSize="large"  />
					          	</IconButton>
							</Grid>
				        </div>
				        <Divider />
				        <Typography  className={"header-lang "+ classes.menulist }>
							<Link id="en_lang" href="en" className={"link1 text-color-default "+this.state.active_en} onClick={this.handleLanguage}>English</Link>&nbsp;|&nbsp; 
							<Link id="ina_lang" href="ina" className={"link1 text-color-default "+this.state.active_ina}  onClick={this.handleLanguage}>Indonesia</Link>
						</Typography>
				        <MenuList className={classes.menulist}  autoFocusItem={this.state.open} id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
				        	
				        	<Link  href={global.url+"/aktifitas"} className="header-menu-item"><MenuItem>{i18n.t('web_header_aktifitas')}</MenuItem></Link>
				        	<Link href={global.url+"/fokus"} className="header-menu-item"><MenuItem>{i18n.t('web_header_fokus')}</MenuItem></Link>
				        	<Link className="header-menu-item"  onClick={this.handleToggle}>
				        		<Grid container alignItems="center" >
				        			<Grid item sm={11} xs={11} >
				        				<MenuItem>{i18n.t('web_header_lebih')} </MenuItem> 
				        			</Grid>
				        			<Grid item sm={1} xs={1}>
				        				{this.state.open ? <ExpandLess  fontSize="large"   /> : <ExpandMore  fontSize="large"   />}
				        			</Grid>
				        		</Grid>
				        		

				        	</Link>
				        	<Collapse className={classes.collapse} in={this.state.open} timeout="auto" unmountOnExit>
						         <a className="text-color-default" href={global.url+"/publikasi"} style={{textDecoration:"none"}} ><MenuItem onClick={this.handleMenuClose}>PUBLIKASI</MenuItem></a>
			                    <a className="text-color-default" href={global.url+"/gallery"} style={{textDecoration:"none"}} ><MenuItem onClick={this.handleMenuClose}>GALLERY</MenuItem></a>
			                    <a className="text-color-default" href={global.url+"/newsletter"} style={{textDecoration:"none"}} ><MenuItem onClick={this.handleMenuClose}>NEWSLETTER</MenuItem></a>
						    </Collapse>
						    <Link className="link1 text-color-green" onClick={this.handleToggle2}>
				        		<Grid container alignItems="center" >
				        			<Grid item sm={11} xs={11} >
				        				<MenuItem><strong>{i18n.t('web_header_managemen')}</strong> </MenuItem> 
				        			</Grid>
				        			<Grid item sm={1} xs={1} >
				        				{this.state.open2 ? <ExpandLess  fontSize="large"   /> : <ExpandMore  fontSize="large"   />}
				        			</Grid>
				        		</Grid>
				        		

				        	</Link>
				        	<Collapse className={classes.collapse} in={this.state.open2} timeout="auto" unmountOnExit>
						         {	

			                  		this.state.data.map((item,index) => {
			                  			return (
			                  				<a className="text-color-default" href={item.url} target="_blank" style={{textDecoration:"none"}} >
			                  					<MenuItem onClick={this.handleMenuClose2}>{item.name}</MenuItem>
			                  				</a>
			                  				
			                  			)
			                  		})
			                  	}
			                  	<a className="text-color-default" href={global.url+"/aplikasi-pktl"} style={{textDecoration:"none"}} ><MenuItem onClick={this.handleMenuClose2}>Aplikasi PKTL</MenuItem></a>
						    </Collapse>



				        </MenuList>
				          	
				        
				</Drawer>


			</div>
		);
	}


}

export default withTranslation()(withStyles(useStyles)(HeaderMobile));