import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
	Link,
	Typography,
	Paper,
	Grid,
	Tabs,
	Tab,
	Icon,
	GridList,
	GridListTile,
	Box,
	Button
} from '@material-ui/core' 

import { withTranslation } from 'react-i18next';
import i18n from './../i18n.js';

import './index.css';
import API from './../api.js';
import LanguageSelector from './../LanguageSelector.js'


class BerandaAktifitas extends React.Component{

	constructor(props){
		super(props);
		this.state = {
			data: [],
			data_ina: [],
			data_en: [],
			limit : 7,
		}
	}

	componentDidMount(){
		API.get('/aktifitas-proyek')
			.then( res => {
				var apires = res.data.response;
				const data_ina = []
				const data_en = []
				apires.data.forEach( item => {

					if(item.name_ina == null || item.name_ina == "null"){
						item.name_ina = ""
					}

					if(item.name_en == null || item.name_en == "null"){
						item.name_en = ""
					}

					if(item.description_ina == null || item.description_ina == "null"){
						item.description_ina = ""
					}

					if(item.description_en == null || item.description_en == "null"){
						item.description_en = ""
					}

					item.name_ina.length > 100 ? item.name_ina = item.name_ina.substr(0,100) + "...." : item.name_ina = item.name_ina ;
					item.name_en.length > 100 ? item.name_en = item.name_en.substr(0,100) + "...." : item.name_en = item.name_en ;
					
					data_ina.push({
						id: item.id,
						name: item.name_ina,
						description: item.description_ina,
						thumbnail: item.thumbnail,
						date: item.created_at.substr(0,10)
					})
					data_en.push({
						id: item.id,
						name: item.name_en,
						description: item.description_en,
						thumbnail: item.thumbnail,
						date: item.created_at.substr(0,10)
					})
				})

				this.setState({
					data: data_ina,
					data_ina: data_ina,
					data_en: data_en,

				})
			})
			.catch((error)=>{
				var err = error.response;
	

			})
	}

	static getDerivedStateFromProps(nextProps, prevState){

		if(nextProps.i18n.language == "ina"){
			return {
				data: prevState.data_ina
			}
		}else{
			return {
				data: prevState.data_en
			}
		}
	}


	render(){
		return(
			<Paper className="section proyek-section" elevation={0} >
					<Grid container>
						
						<Grid container item lg={12} md={12} sm={12}  xs={12} spacing={3}>
						{
							this.state.data.slice(0, this.state.limit).map((item, index, arr)=> {
								var grid
								if(index == 0 || index == 3 ){
									grid = 8
								}
								else {
									grid = 4
								}

								return(
									<Grid item lg={grid} md={grid} sm={12} xs={12} >
										<a href={global.url+"/aktifitas-content/" + item.id}>
											<div className="container-gallery">
												<img src={global.url_be_storage_public+"/"+item.thumbnail}  alt="" className="image-gallery" />
												
												<div className="image-gallery-overlay">
													<p>{item.name}</p>
												</div>
											</div>
										</a>
										
									</Grid>

								)
								
							})
						}


							
						</Grid>
						
					</Grid>
				</Paper>

		)
	}


}


export default withTranslation()(BerandaAktifitas);