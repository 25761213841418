import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';	
import {
	Link,
	Typography,
	Paper,
	Grid,
	Tabs,
	Tab,
	Icon,
	GridList,
	GridListTile,
	Box,
	Button,
	TextField,
	InputAdornment,
	Backdrop
} from '@material-ui/core' 
import IconButton from '@material-ui/core/IconButton';
import Pagination from '@material-ui/lab/Pagination';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import SearchIcon from '@material-ui/icons/Search';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { withTranslation } from 'react-i18next';
import i18n from './../i18n.js';


import './index.css';
import API from './../api.js';
import LanguageSelector from './../LanguageSelector.js'

import Cover from './Cover.jsx';


const useStyles = {
	container: {
		minHeight: "100vh",
		paddingTop:"5vh",
		paddingLeft: "10vh",
		paddingRight: "10vh",
		"@media (max-width: 600px)" : {
			paddingLeft: "3vh",
			paddingRight:"1vh",
			paddingBottom: "3vh"
		},
		"@media (min-width: 1444px)" : {
			paddingLeft: "2vh",
			paddingRight: "2vh",
			paddingBottom: "2vh",
			minHeight: "50vh",
		}
		
		
	},
	viewMore: {
		width: '100%',
		height: "10vh"
	},
	divImage: {
		width: "100%",
		height: "auto",
		paddingLeft: "25%",

		
	},
	containerGallery: {
		padding: "10%",
		backgroundColor: "rgba(242, 242, 242, 1)",
		borderTopLeftRadius: "1em",
		borderTopRightRadius: "1em",
		

	},
	imageGallery: {
		width: "100%",
		height: "20vh",
		objectFit: "cover",
		"@media (max-width: 768px)" : {
			height:"20vh"
		},
		"@media (min-width: 1444px)" : {
			height: "10vh"
		}
	},
	imageArrow: {
		cursor: "pointer",
		paddingLeft: "7vw",
		"@media (max-width: 600px)" : {
			paddingLeft:"2vw"	
		}

		
	},
	postedOn: {
		marginTop: "5vh"
	},
	breadcrumbContainer: {
		marginTop:"5vh", 
		marginBottom:"5vh",
		"@media (max-width: 600px)" : {
			marginTop:"1vh", 
			marginBottom:"1vh",
		},
		"@media (min-width: 1444px)" : {
			marginTop:"3vh", 
			marginBottom:"3vh",
		}
	},
	
};

class AplikasiPktl extends React.Component{

	constructor(props){
		super(props)
		this.state = {
			breadcrumb: "Aplikasi PKTL",
			breadcrumb_ina: "Aplikasi PKTL",
			breadcrumb_en : "Aplikasi PKTL",
			data: [],
			data_ina: [],
			data_en: [],
			page: 1,
			count: 1,
			limit : 5,
			filterCari: "",
			openBackdrop: false,
			backdropImage: "",
			backdropIndex: 0,
			backdropPrev: -1,
			backdropNext:0
		}

		this.handlePagination = this.handlePagination.bind(this)
		this.handleToggleBackdrop = this.handleToggleBackdrop.bind(this)
		this.handleCloseBackdrop = this.handleCloseBackdrop.bind(this)
		this.handlePrev = this.handlePrev.bind(this)
		this.handleNext = this.handleNext.bind(this)
	}

	componentDidMount(){
		
		API.get('/aplikasi-lingkup')
			.then(res=> {
				var apires = res.data.response;
				const data_ina= [];
				const data_en= [];
				const count = Math.floor(apires.data.length / 9)
				apires.data.forEach( item => {

					if(item.name_ina == null){
						item.name_ina = ""
					}
					if(item.name_en == null){
						item.name_en = ""
					}

					

					data_ina.push({
						id: item.id,
						name: item.name_ina,
						url: item.url,
						logo: item.logo,
						date: item.created_at.substr(0,10)
					});

					data_en.push({
						id: item.id,
						name: item.name_en,
						url: item.url,
						logo: item.logo,
						date: item.created_at.substr(0,10)
					});

					
				})

				
				this.setState({
					
					data: data_ina,
					data_ina: data_ina,
					data_en: data_en,
					count: count + 1
				})
			})
			.catch((error)=>{
				var err = error.response;
	
				
				
			})
	}

	static getDerivedStateFromProps(nextProps, prevState){

		if(nextProps.i18n.language == "ina"){
			return {
				data: prevState.data_ina,
				
			}
		}else{
			return {
				data: prevState.data_en,
				
			}
		}
	}

	handlePagination(e){

		this.setState({page:parseInt(e.target.textContent)})
		
	}

	handleToggleBackdrop(image_index){
		

		this.setState({openBackdrop: !this.state.openBackdrop, backdropImage: this.state.data[image_index].image, backdropIndex: image_index})

	}

	handleCloseBackdrop(){
		this.setState({openBackdrop: false})
	}

	handlePrev(){
		var curr = this.state.page
		if(curr == 1){
			var prev = curr
		}else{
			var prev = curr - 1
		}

		this.setState({ page: prev})
	}

	handleNext(){
		var curr = this.state.page
		if(curr == this.state.count){
			var next = curr
		}else{
			var next = curr + 1
		}

		this.setState({ page: next})
	}


	render(){

		const { classes } = this.props;

		
		return(
			<div>

				<Grid className="breadcrumb-container"   >
					<Grid item md={1} sm={1} xs={1}>
						
					</Grid>
					<Grid item md sm xs >
						<Breadcrumbs aria-label="breadcrumb">
						 	<a className="text-color-default" href={global.url+"/beranda"} style={{textDecoration:"none"}} >KalFor</a> | 
						 
						 	<Typography className="text-color-green">{this.state.breadcrumb}</Typography>
						 </Breadcrumbs>
					</Grid>
				</Grid>
				
				<Grid container alignItems="center">
					<Grid item md={2} sm={2} xs={2} >
						<div  className={classes.imageArrow}  >
							<IconButton size="large" onClick={this.handlePrev} >
								<NavigateBeforeIcon  fontSize="large" />
							</IconButton>
						</div>
						
						
					</Grid>
					<Grid item md={8} sm={8} xs={8}>
						<Container className={classes.container}>
							<Grid container spacing={3}>
								{
									this.state.data.slice((this.state.page - 1) * 8, (this.state.page * 8) ).map((item, index) => {

										return (
											<Grid item md={3} sm={6} xs={12} >
												<Paper elevation={5}  style={{ borderRadius:"1rem" }}>
													<a href={item.url} target="_blank">
														<div className={"container-gallery " + classes.containerGallery}>
															<Grid container >
																<img src={global.url_be_storage_public+"/"+ item.logo} alt="" className={classes.imageGallery} />
															</Grid>
															
														</div>
													</a>
													
													
													
													<Grid container justify="center" style={{paddingTop:"3vh", paddingBottom: "3vh"}}> 
															{item.name}
													</Grid>
												</Paper>

												
											</Grid>
										);
											
											
										
									})
								}
							</Grid>
							
					
						</Container>
					</Grid>
					<Grid item md={2} sm={2} xs={2} >
						<div  className={classes.imageArrow}  >
							<IconButton size="large" onClick={this.handleNext} >
								<NavigateNextIcon  fontSize="large" />
							</IconButton>
						</div>
						
						
					</Grid>
				</Grid>
				
				
			</div>
		)
	}


}


export default withTranslation()(withStyles(useStyles)(AplikasiPktl));