// global.root = "http://localhost:5000";
// global.url = "http://localhost:5000";
// global.url_be = "http://localhost:8000";
// global.url_be_storage_public = "http://localhost:8000/storage";
global.root = "https://kalimantanforest.org";
global.url = "https://kalimantanforest.org";
global.url_be = "https://ipsdh.menlhk.go.id/be";
global.url_be_storage_public = "https://ipsdh.menlhk.go.id/be/storage";
global.api_config = {
	headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
}