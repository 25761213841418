import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';	
import {
	Link,
	Typography,
	Paper,
	Grid,
	Tabs,
	Tab,
	Icon,
	GridList,
	GridListTile,
	Box,
	Button,
	TextField,
	InputAdornment,
	Backdrop
} from '@material-ui/core' 
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Pagination from '@material-ui/lab/Pagination';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { withTranslation } from 'react-i18next';
import i18n from './../i18n.js';


import './index.css';
import API from './../api.js';
import LanguageSelector from './../LanguageSelector.js'

import Cover from './Cover.jsx';


const useStyles = (theme) => ({
	container: {
		minHeight: "20vh",
		paddingTop:"5vh",
		paddingBottom: "10vh",
		"@media (max-width: 600px)" : {
			paddingLeft: "8vw",
			paddingRight: "8vw",
		},
		"@media (min-width: 1444px)" : {
			paddingRight: "2vh",
			paddingBottom: "5vh"
		}
	},
	viewMore: {
		width: '100%',
		height: "10vh"
	},
	divImage: {
		width: "100%",
		height: "auto",
		paddingLeft: "25%",
		"@media (max-width: 600px)" : {
			paddingLeft: "0%",
			
		},
		"@media (max-width: 768px)" : {
			paddingLeft: "0%",
			
		}
		
	},
	imageGallery: {
		width: "60%",
		height: "auto",
		"@media (max-width: 768px)" : {
			width:"100%"
			
		},
		"@media (max-width: 600px)" : {
			width:"100%"
			
		}
	},
	imageArrow: {
		cursor: "pointer",
		padding: "2vw",
		height: "100%"
	},
	postedOn: {
		marginTop: "5vh"
	},
	backdrop: {
	    zIndex: theme.zIndex.drawer + 1,
	    color: '#fff',
	 //    "@media (max-width: 768px)" : {
		// 	marginTop: "-20vh"
		// }
	  },
	pagination: {
	    "& .Mui-selected": {
	      backgroundColor: "rgba(63, 183, 118, 1)",
	      borderColor : "rgba(63, 183, 118, 1)",
	      color: "white",
	      
	    },
	    "& .MuiPaginationItem-root": {
	    	"&:hover": {
		    	
		      	backgroundColor: "lightgray",
		      	borderColor : "lightgray",
		    }
	    }    
	},
	breadcrumbContainer: {
		marginTop:"5vh", 
		marginBottom:"5vh",
		"@media (max-width: 600px)" : {
			marginTop:"1vh", 
			marginBottom:"1vh",
		},
		"@media (min-width: 1444px)" : {
			marginTop:"3vh", 
			marginBottom:"3vh",
		}
	},
	
});

class GalleryContent extends React.Component{

	constructor(props){
		super(props)
		this.state = {
			breadcrumb: "Galeri",
			breadcrumb_ina: "Galeri",
			breadcrumb_en : "Gallery",
			data: [],
			page: 1,
			count: 1,
			limit : 5,
			filterCari: "",
			openBackdrop: false,
			backdropImage: "",
			backdropIndex: 0,
			backdropPrev: -1,
			backdropNext:0
		}

		this.handlePagination = this.handlePagination.bind(this)
		this.handleToggleBackdrop = this.handleToggleBackdrop.bind(this)
		this.handleCloseBackdrop = this.handleCloseBackdrop.bind(this)
		this.handlePrev = this.handlePrev.bind(this)
		this.handleNext = this.handleNext.bind(this)
	}

	componentDidMount(){
		
		API.get('/gallery?id='+this.props.id)
			.then(res=> {
				var apires = res.data.response;
				const data= [];
				const folder_name = apires.data[0].name
				const count = Math.floor(apires.data[0].gallery.length / 9)
				apires.data[0].gallery.forEach( item => {
					data.push({
						id: item.id,
						date: item.created_at,
						image: item.image_gallery
					});

					
				})

				this.setState({
					folder_name: folder_name,
					data: data,
					count: count + 1
				})
			})
			.catch((error)=>{
				var err = error.response;
		
				
				
			})
	}

	handlePagination(e){

		this.setState({page:parseInt(e.target.textContent)})
		
	}

	handleToggleBackdrop(image_index){
		

		this.setState({openBackdrop: !this.state.openBackdrop, backdropImage: this.state.data[image_index].image, backdropIndex: image_index})

	}

	handleCloseBackdrop(){
		this.setState({openBackdrop: false})
	}

	handlePrev(){
		var curr = this.state.backdropIndex
		if(curr == 0){
			var prev = this.state.data.length - 1
		}else{
			var prev = curr - 1
		}

		this.setState({ backdropImage: this.state.data[prev].image, backdropIndex: prev})
	}

	handleNext(){
		var curr = this.state.backdropIndex
		if(curr == (this.state.data.length - 1)){
			var next = 0
		}else{
			var next = curr + 1
		}

		this.setState({ backdropImage: this.state.data[next].image, backdropIndex: next})
	}


	render(){

		const { classes } = this.props;

		const filteredList = this.state.data.filter(x => this.state.filterCari == '' ||(x.name && x.name.toLowerCase().includes(this.state.filterCari.toLowerCase())))
		const cari = <TextField id="outlined-basic" label={ i18n.t('tabel_cari.label')} InputProps={{startAdornment: (<InputAdornment position="start"><SearchIcon /> </InputAdornment> )}} size="small" variant="outlined" onChange={this.handleCari} />


		return(
			<div>
				
				<Backdrop className={classes.backdrop} open={this.state.openBackdrop} >
					<ArrowBackIosIcon className={classes.imageArrow} fontSize="large" onClick={this.handlePrev} />
					<div className={classes.divImage} onClick={this.handleCloseBackdrop}>
						<img src={global.url_be_storage_public+"/"+this.state.backdropImage} alt="" className={classes.imageGallery}  />
					</div>
					
			       	
			       	<ArrowForwardIosIcon className={classes.imageArrow} fontSize="large" onClick={this.handleNext} />
					      
				</Backdrop>


				<Grid  className="breadcrumb-container"   >
					<Grid item md={1} sm={1} xs={1} >
						
					</Grid>
					<Grid item md sm xs >
						<Breadcrumbs aria-label="breadcrumb">
						 	<a className="text-color-default" href={global.url+"/beranda"} style={{textDecoration:"none"}} >KalFor</a> | 
						 	<a className="text-color-default"  href={global.url+"/gallery"} style={{textDecoration:"none"}} >{this.state.breadcrumb}</a>
						 	<Typography className="text-color-green">{this.state.folder_name}</Typography>
						 </Breadcrumbs>
					</Grid>
				</Grid>
				
				<Grid container>
					<Grid item lg={2} md={2} xs={2}></Grid>
					<Grid item lg={8} md={8} xs={8} >
						<Container className={classes.container}>
							<Grid container spacing={3}>
								{
									this.state.data.slice((this.state.page - 1) * 9, (this.state.page * 9) ).map((item, index) => {

										return (
											<Grid item lg={4} md={4} sm={12} xs={12} >
												<div className="container-gallery">
													
													<img src={global.url_be_storage_public+"/"+ item.image} alt="" className="image-gallery" onClick={()=>this.handleToggleBackdrop(((this.state.page - 1) * 9) + index)} />
													
													<div className="image-gallery-overlay" onClick={()=>this.handleToggleBackdrop(((this.state.page - 1) * 9) + index)} >
														<Grid className="icon-overlay" container justify="flex-end" > 
														<Grid item lg={10} md={10}>
														</Grid>
														
														{/*<Grid item md={2}>
															<DeleteIcon className="action-icon" onClick={()=>this.handleDelete(item.id)}  />
														</Grid>*/}
													</Grid>
													</div>
												</div>
											</Grid>
										);
											
											
										
									})
								}
							</Grid>
							<Grid container justify="flex-end" style={{ marginTop:"5vh"}}>
								<Pagination className={classes.pagination} count={this.state.count} variant="outlined" shape="rounded"  siblingCount={1} onChange={this.handlePagination} />
							</Grid>
					
						</Container>
					</Grid>
				</Grid>
				
				
			</div>
		)
	}


}


export default withTranslation()(withStyles(useStyles)(GalleryContent));