import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';	
import {
	Link,
	Typography,
	Paper,
	Grid,
	Tabs,
	Tab,
	Icon,
	GridList,
	GridListTile,
	Box,
	Button,
	TextField,
	InputAdornment
} from '@material-ui/core' 
import Pagination from '@material-ui/lab/Pagination';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import SearchIcon from '@material-ui/icons/Search';
import { withTranslation } from 'react-i18next';
import i18n from './../i18n.js';


import './index.css';
import API from './../api.js';
import LanguageSelector from './../LanguageSelector.js'

import Cover from './Cover.jsx';


const useStyles = {
	container: {
		minHeight: "20vh",
		paddingTop:"10vh",
		paddingBottom: "10vh",
		paddingLeft: "20vh",
		paddingRight: "27vh",
		"@media (max-width: 768px)" : {
			paddingLeft: "10vw",
			paddingRight: "10vw"
		},
		"@media (min-width: 1444px)" : {
			paddingLeft: "0vh",
			paddingRight: "0vh",
			paddingBottom: "5vh"
		}
	},
	viewMore: {
		width: '100%',
		height: "10vh"
	},
	pagination: {
	    "& .Mui-selected": {
	      backgroundColor: "rgba(63, 183, 118, 1)",
	      borderColor : "rgba(63, 183, 118, 1)",
	      color: "white",
	      
	    },
	    "& .MuiPaginationItem-root": {
	    	"&:hover": {
		    	
		      	backgroundColor: "lightgray",
		      	borderColor : "lightgray",
		    }
	    }    
	},
	breadcrumbContainer: {
		marginTop:"5vh", 
		marginBottom:"5vh",
		"@media (max-width: 600px)" : {
			marginTop:"1vh", 
			marginBottom:"1vh",
		},
		"@media (min-width: 1444px)" : {
			marginTop:"3vh", 
			marginBottom:"3vh",
		}
	},
	
};

class Gallery extends React.Component{

	constructor(props){
		super(props)
		this.state = {
			breadcrumb: "Galeri",
			breadcrumb_ina: "Galeri",
			breadcrumb_en : "Gallery",
			data: [],
			page: 1,
			count: 1,
			limit : 9,
			filterCari: "",
		}

		
		this.handlePagination = this.handlePagination.bind(this)
	}

	componentDidMount(){
		
		API.get('/gallery-folder')
			.then(res=> {
				var apires = res.data.response;
				const data= [];
				const count = Math.ceil(apires.data.length / 9)
				apires.data.forEach( item => {
		
					if(item['gallery_latest'] == null){
						return;
					}

					if(item.name == null){
						item.name = ""
					}

				

					data.push({
						id: item.id,
						name: item.name,
						date: item.created_at,
						image: item['gallery_latest'].image_gallery
					});

					
				})



				this.setState({
					data: data,
					count: count
				})



			})
			.catch((error)=>{
				var err = error.response;
	
				
			})

	}

	static getDerivedStateFromProps(nextProps, prevState){

		if(nextProps.i18n.language == "ina"){
			return {
				breadcrumb: prevState.breadcrumb_ina			
			}
		}else{
			return {
				breadcrumb: prevState.breadcrumb_en
			}
		}
	}

	
	handlePagination(e){
	
		this.setState({page:parseInt(e.target.textContent)})
	}


	render(){

		const { classes } = this.props;

	
		return(
			<div>
				<Grid  className="breadcrumb-container"  >
					
					<Grid item md sm xs >
						<Breadcrumbs aria-label="breadcrumb">
						 	<a className="text-color-default" href={global.url+"/beranda"} style={{textDecoration:"none"}} >KalFor</a> | 
						 	<Typography className="text-color-green">{this.state.breadcrumb}</Typography>
						 </Breadcrumbs>
					</Grid>
				</Grid>
				
				<Cover menu_name={this.state.breadcrumb} />
				<Container className={classes.container}>
						
					<Grid container spacing={3}>
					{
						this.state.data.slice((this.state.page - 1) * 9, (this.state.page * 9)  ).map((item, index) => {
						
							return (
								<Grid item lg={4} md={4} sm={12} xs={12} >
									
										<a href={global.url+"/gallery-content/"+item.id} >
											<div className="div-gallery-multi" >
												<hr className="gallery-multi"  style={{width:"70%"}} />
												<hr className="gallery-multi"  style={{width:"80%"}} />
												<hr className="gallery-multi"  style={{width:"87%"}} />
											</div>
											<div className="container-gallery">
												
												<img src={global.url_be_storage_public+"/"+ item.image} alt="" className="image-gallery" />
												
												<div className="image-gallery-overlay">
													<p>{item.name}</p>
												</div>
											</div>
										</a>

										
										<Grid container justify="flex-end" style={{marginTop:"1vh"}}> 
											
										</Grid>
									
								</Grid>
							);
						})
					}
					</Grid>
					<Grid container justify="flex-end" style={{ marginTop:"5vh"}}>
						<Pagination className={classes.pagination}  count={this.state.count} variant="outlined" shape="rounded" siblingCount={1} onChange={this.handlePagination} />
					</Grid>
					
				</Container>
			</div>
		)
	}


}


export default withTranslation()(withStyles(useStyles)(Gallery));