import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
	Link,
	Typography,
	Paper,
	Grid,
	Tabs,
	Tab,
	Icon,
	GridList,
	GridListTile,
	Box,
	Button
} from '@material-ui/core' 
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import i18n from './../i18n.js';

import './index.css';
import API from './../api.js';
import LanguageSelector from './../LanguageSelector.js'
import ImageBackground from './../login_image.png';



const useStyles = {
	cover: {
		background : `url(${ImageBackground}) no-repeat center center fixed`,
		backgroundSize: "cover",
		height: "60vh",

		color: "white",
		fontSize: "2em",
		"@media (max-width: 768px)" : {
			height:"20vh"
		},
		"@media (max-width: 600px)" : {
			fontSize: "1.2em",
			height:"20vh"
		},
		"@media (min-width: 1444px)" : {
			fontSize: "2em",
			height:"30vh"
		}
	},
	footerOverlay: {
		bottom: 0,
		height: '100%',
		width: '100%',
		backgroundImage: 'linear-gradient(to bottom, rgba( 11, 69, 38,0), rgba( 11, 69, 38,0.5))'
	},
	footerContainer: {
		bottom: '25vh',
		left: 0,
		paddingLeft: '12vw',
		position: 'absolute',
		color: 'white',
		width:'80%',

		lineHeight: 0.2,
		"@media (max-width: 768px)" : {
			bottom:"62vh",
			paddingLeft: "10vw"
		},
		"@media (max-width: 600px)" : {
			bottom:"68vh",
			paddingLeft: "8vw"
		},
		"@media (min-width: 1444px)" : {
			bottom:"58vh",
			paddingLeft: "12vw"
		}
	},
	
};


class Cover extends React.Component{

	constructor(props){
		super(props)
	}

	render(){

		const { classes } = this.props;


		return(
			<div>


				 <Grid container justify="flex-start" alignItems="center" className={classes.cover}>
				 	<div className={classes.footerOverlay}></div>
				 	<div className={classes.footerContainer}>
				 		<Grid item md={1}></Grid>
					 	<Grid>
					 		<h3 >Kalfor</h3>
					 		<h1 >{this.props.menu_name}</h1>
					 	</Grid>
				 	</div>
				 	
				 </Grid>


			</div>
		)
	}

}


export default  withStyles(useStyles)(Cover);