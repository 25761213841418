import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';	
import {
	Link,
	Typography,
	Paper,
	Grid,
	Tabs,
	Tab,
	Icon,
	GridList,
	GridListTile,
	Box,
	Button,
	TextField,
	InputAdornment,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@material-ui/core' 
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SearchIcon from '@material-ui/icons/Search';
import { withTranslation } from 'react-i18next';
import i18n from './../i18n.js';


import './index.css';
import API from './../api.js';
import LanguageSelector from './../LanguageSelector.js'

import Cover from './Cover.jsx';


const useStyles = {
	container: {
		minHeight: "100vh",
		paddingTop:"10vh",
		paddingBottom: "10vh",
		paddingLeft: "8vh",
		paddingRight: "12vh",
		"@media (max-width: 768px)" : {
			paddingBottom:"5vh"
		},
		"@media (max-width: 600px)" : {
			paddingBottom:"5vh",
			paddingLeft: "0vh",
			paddingRight: "0vh",
		},
		"@media (min-width: 1444px)" : {
			paddingTop:"5vh",
		}
		
		
	},
	viewMore: {
		width: '100%',
		height: "10vh"
	},
	divImage: {
		width: "100%",
		height: "auto",
		paddingLeft: "25%",

		
	},
	containerGallery: {
		paddingTop: "10%",
		paddingLeft: "10%",
		paddingRight: "10%",

		backgroundColor: "rgba(242, 242, 242, 1)",
		borderTopLeftRadius: "1em",
		borderTopRightRadius: "1em"
	},
	imageGallery: {
		width: "100%",
		
		objectFit: "cover",
		borderTopLeftRadius: "1em",
		borderTopRightRadius: "1em"
	},
	textGallery: {
		paddingTop: "5vh",
		paddingLeft: "10%",
		paddingBottom: "10vh",
		"@media (min-width: 1444px)" : {
			paddingTop: "0vh",
			paddingBottom: "0vh"
		}
	},
	imageArrow: {
		cursor: "pointer",
		paddingLeft: "7vw",

		
	},
	postedOn: {
		marginTop: "5vh"
	},
	buttonImg:{
		paddingTop:"5vh",
		paddingBottom:"7vh", 
		paddingLeft: "10%", 
		paddingRight:"10%",
		"@media (max-width: 600px)" : {
			paddingLeft: "12vw",
			paddingRight: "10vw",
			paddingTop:"5vh",
		},
		"@media (min-width: 1444px)" : {
			paddingTop:"3vh",
			paddingBottom:"5vh", 
			
		}
	},
	flipbook:{
		width:"60vw", 
		height: "80vh",
		"@media (max-width: 768px)" : {
			height:"40vh"
		},
		"@media (min-width: 1444px)" : {
			width:"60vw", 
			height: "80vh",
		}
	},
	flipbookIframe: {
		width: "100%", 
		height:"90%",
		"@media (min-width: 1444px)" : {
			width:"90%", 
			
		}

	},
	breadcrumbContainer: {
		marginTop:"5vh", 
		marginBottom:"5vh",
		"@media (max-width: 600px)" : {
			marginTop:"1vh", 
			marginBottom:"1vh",
		},
		"@media (min-width: 1444px)" : {
			marginTop:"3vh",  
			marginBottom:"3vh",
		}
	},
	
	
};

class NewsletterContent extends React.Component{

	constructor(props){
		super(props)
		this.state = {
			breadcrumb: "Newsletter",
			breadcrumb_ina: "Newsletter",
			breadcrumb_en : "Newsletter",
			data: [],
			data_ina: [],
			data_en: [],
			limit : 5,
			filterCari: "",
			open_pdf: false
		}

		this.handleLoadMore = this.handleLoadMore.bind(this)
		this.handleCari = this.handleCari.bind(this)
		this.handleOpenPdf = this.handleOpenPdf.bind(this)
		this.closeDialog = this.closeDialog.bind(this)
	}

	componentDidMount(){
		
		API.get('/newsletter-id?id='+this.props.id)
			.then( res => {
				var apires = res.data.response;
				const data_ina = []
				const data_en = []
				apires.data.forEach( item => {

					if(item.name_ina == null){
						item.name_ina = ""
					}

					if(item.name_en == null){
						item.name_en = ""
					}
					
					if(item.description_ina == null){
						item.description_ina = ""
					}

					if(item.description_en == null){
						item.description_en = ""
					}

					data_ina.push({
						id: item.id,
						name: item.name_ina,
						description: item.description_ina,
						thumbnail: item.thumbnail,
						file: item.file_document,
						date: item.created_at.substr(0,10)
					})
					data_en.push({
						id: item.id,
						name: item.name_en,
						description: item.description_en,
						thumbnail: item.thumbnail,
						file: item.file_document,
						date: item.created_at.substr(0,10)
					})
				})

				this.setState({
					data: data_ina,
					data_ina: data_ina,
					data_en: data_en,
				})

			})
			.catch((error)=>{
				var err = error.response;
				
				
			})
	}

	static getDerivedStateFromProps(nextProps, prevState){

		if(nextProps.i18n.language == "ina"){
			return {
				data: prevState.data_ina,
				breadcrumb: prevState.breadcrumb_ina			
			}
		}else{
			return {
				data: prevState.data_en,
				breadcrumb: prevState.breadcrumb_en
			}
		}
	}

	handleCari(e){
		this.setState({filterCari: e.target.value})
	}

	handleLoadMore(){
		var data_length = this.state.data.length
		var remain_data = data_length - this.state.limit
		if(remain_data < 5){
			var load_more = remain_data
		}else{
			var load_more = 5;
		}

		this.setState({limit : this.state.limit + load_more})
	}

	handleOpenPdf(){
		
		this.setState({ open_pdf: true})
	}

	closeDialog(e){
		this.setState({ open_pdf: false})
	}


	render(){

		const { classes } = this.props;

		const filteredList = this.state.data.filter(x => this.state.filterCari == '' ||(x.name && x.name.toLowerCase().includes(this.state.filterCari.toLowerCase())))
		const cari = <TextField id="outlined-basic" label={ i18n.t('tabel_cari.label')} InputProps={{startAdornment: (<InputAdornment position="start"><SearchIcon /> </InputAdornment> )}} size="small" variant="outlined" onChange={this.handleCari} />


		return(
			<div>
				<Grid  className="breadcrumb-container"   >
					
					<Grid item md sm xs >
						<Breadcrumbs aria-label="breadcrumb">
						 	<a className="text-color-default" href={global.url+"/beranda"} style={{textDecoration:"none"}} >KalFor</a> | 
						 	<a className="text-color-default"  href={global.url+"/newsletter"} style={{textDecoration:"none"}} >{this.state.breadcrumb}</a>
						 	<Typography className="text-color-green">{this.state.data[0] ? this.state.data[0].name.substr(0, 30) + (this.state.data[0].name.length > 30 ? "..." : "") : ""}</Typography>
						 </Breadcrumbs>
					</Grid>
				</Grid>
				
				<Grid container>
					<Grid item lg={1} md={1} sm={1} xs={1} ></Grid>
					<Grid item lg={10} md={10} sm={10} xs={10} >
						<Container className={classes.container}>
							<Grid container spacing={4}>
								<Grid item lg={6} md={6}>
									<Paper elevation={5}  style={{ borderRadius:"1rem" }}>
													
										<div className={"container-gallery " + classes.containerGallery}>
											<Grid container >
												<img src={global.url_be_storage_public+"/"+ (this.state.data[0] ? this.state.data[0].thumbnail : "")} alt="" className={classes.imageGallery} />
											</Grid>
											
										</div>
										<div className={"div-center "+classes.buttonImg}  >
							        	 	<Grid container spacing={3} alignItems="center">
								        	 		<Grid item lg={6} md={6}>
								        	 			<a href={global.url_be_storage_public+"/"+(this.state.data[0] ? this.state.data[0].file : "")} target="_blank" style={{textDecoration:"none"}} >
									        	 			<Button id="modal-submit" type="submit">
													        Download
													        </Button>
													    </a>
								        	 		</Grid>
								        	 		<Grid item md>
									        	 			<Button id="modal-submit" onClick={this.handleOpenPdf}>
													           <VisibilityIcon /> &nbsp;&nbsp; View
													        </Button>
								        	 		</Grid>
								        	 	</Grid>
						  				</div>		
									
										
									</Paper>
								</Grid>
								<Grid item lg={6} md={6}>
									<h1 style={{textAlign:"center"}} >{this.state.data[0] ? this.state.data[0].name : ""}</h1>
									
									<div  dangerouslySetInnerHTML={{ __html: this.state.data[0] ? this.state.data[0].description : "" }} ></div>
									
								</Grid>
							</Grid>

							<Dialog className="dialog-custom" maxWidth="md" open={this.state.open_pdf} onClose={this.closeDialog} aria-labelledby="form-dialog-title"  >
						
						        <DialogContent>
						        	<div  className={classes.flipbook} >
						        		
						        		<iframe src={global.url_be+"/pdf-flipbook?pdf="+(this.state.data[0] ? this.state.data[0].file : "")} className={classes.flipbookIframe} ></iframe>
						        	</div>
								        

						        </DialogContent>
						        
						      </Dialog>


								
						</Container>
					</Grid>
				</Grid>
				
				
			</div>
		)
	}


}


export default withTranslation()(withStyles(useStyles)(NewsletterContent));