import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
	Link,
	Typography,
	Paper,
	Grid,
	Tabs,
	Tab,
	Icon,
	Menu,
	MenuItem,
	Button,
	Popper,
	ClickAwayListener,
	MenuList,
	Grow
} from '@material-ui/core' 

import { withTranslation } from 'react-i18next';
import i18n from './../i18n.js';

import './index.css';
import API from './../api.js';
import LanguageSelector from './../LanguageSelector.js'

class Header extends React.Component{

	constructor(props){
		super(props);
		this.state= {
			language : "",
			anchorE1: "",
			open: false,
			open2: false,
			data: [],
			data_ina: [],
			data_en: [],
			active_ina: "active",
			active_en : "",
			
		}
		this.anchorRef = React.createRef();
		this.anchorRef2 = React.createRef();


		this.handleLanguage = this.handleLanguage.bind(this);
		this.handleMenuOpen = this.handleMenuOpen.bind(this)
		this.handleMenuClose = this.handleMenuClose.bind(this)
		this.handleToggle = this.handleToggle.bind(this)
		this.handleListKeyDown = this.handleListKeyDown.bind(this)


		this.handleMenuOpen2 = this.handleMenuOpen2.bind(this)
		this.handleMenuClose2 = this.handleMenuClose2.bind(this)
		this.handleToggle2 = this.handleToggle2.bind(this)
		this.handleListKeyDown2 = this.handleListKeyDown2.bind(this)
	}

	componentDidMount(){

		API.get('/managemen-pengetahuan')
			.then( res => {
				var apires = res.data.response;
				
				const data_ina = []
				const data_en = []

				

				apires.data.forEach( item => {

					if(item.name_ina == null){
						item.name_ina = ""
					}

					if(item.name_en == null){
						item.name_en = ""
					}


					
					
					data_ina.push({
						id: item.id,
						name: item.name_ina,
						url: item.url,
						thumbnail: item.thumbnail,
						date: item.created_at.substr(0,10)
					})
					data_en.push({
						id: item.id,
						name: item.name_en,
						url: item.url,
						thumbnail: item.thumbnail,
						date: item.created_at.substr(0,10)
					})
				})

				this.setState({
					data: data_ina,
					data_ina: data_ina,
					data_en: data_en,
				})


			})
			.catch((error)=>{
				var err = error.response;
				
				
			})
	}

	static getDerivedStateFromProps(nextProps, prevState){

		if(nextProps.i18n.language == "ina"){
			return {
				data: prevState.data_ina,
				active_ina: "active",
				active_en: ""
			}
		}else{
			return {
				data: prevState.data_en,
				active_ina: "",
				active_en: "active"
			}
		}
	}

	handleLanguage(e){
		e.preventDefault();
		var pathname = e.target.pathname;
		var lang = pathname.replace("/fe/", "");
 		

		i18n.changeLanguage(lang.replace("/", ""))
		localStorage.setItem('lang', lang.replace("/", ""));

		if(lang === "en"){
			document.getElementById('en_lang').classList.add("active");
			document.getElementById('ina_lang').classList.remove("active");
		}else{
			document.getElementById('en_lang').classList.remove("active");
			document.getElementById('ina_lang').classList.add("active");
		}
	}

	handleMenuOpen(e){

		this.setState({anchorE1: e.currentTarget})
		
	}

	handleMenuClose(e){
		this.setState({open: false})
	}

	handleToggle(){
		if(this.state.open == true){
			this.setState({open: false})
		}else{
			this.setState({open: true})
		}
		
	}

	handleListKeyDown(e){
		if (e.key === 'Tab') {
	      e.preventDefault();
	      this.setState({open: false})
	    }
	}

	handleMenuOpen2(e){

		this.setState({anchorE1: e.currentTarget})
		
	}

	handleMenuClose2(e){
		this.setState({open2: false})
	}

	handleToggle2(){
		if(this.state.open2 == true){
			this.setState({open2: false})
		}else{
			this.setState({open2: true})
		}
		
	}

	handleListKeyDown2(e){
		if (e.key === 'Tab') {
	      e.preventDefault();
	      this.setState({open2: false})
	    }
	}


	render(){

		return(
			<div>
				<Paper elevation={3} square>
					<Paper square elevation={0}>
						<Typography  className="header-lang ">
							<Link id="en_lang" href="en" className={"link1 text-color-default "+this.state.active_en} onClick={this.handleLanguage}>English</Link>&nbsp;|&nbsp; 
							<Link id="ina_lang" href="ina" className={"link1 text-color-default "+this.state.active_ina}  onClick={this.handleLanguage}>Indonesia</Link>
						</Typography>
					</Paper>
					<Grid container className="header-menu">
						<Grid container item md={5}>
							<Grid item md={1}></Grid>
							<Grid item md={6}>
								<Link href={global.url+"/beranda"} >
									<img src={process.env.PUBLIC_URL + "/images/u2.png"} alt=""   style={{width:"80%"}} />
								</Link>
							</Grid> 
							
						</Grid>
						<Grid container item md={7}  style={{alignItems:"center"}}>
							<Grid item md={3}>
								<Link href={global.url+"/aktifitas"} className="header-menu-item">{i18n.t('web_header_aktifitas')}</Link>
							</Grid>
							<Grid item md={3}>
								<Link href={global.url+"/fokus"} className="header-menu-item">{i18n.t('web_header_fokus')}</Link>
							</Grid>
							<Grid item md={3} >
								<div>
									<Link className="header-menu-item" ref={this.anchorRef} aria-controls={this.state.open ? 'lebih-menu' : undefined} aria-haspopup="true" onClick={this.handleToggle}>{i18n.t('web_header_lebih')}</Link>
									<Popper open={this.state.open} anchorEl={this.anchorRef.current} role={undefined} transition disablePortal>
										{
											({TransitionProps, placement}) => (
												<Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
													<Paper>
										                <ClickAwayListener onClickAway={this.handleMenuClose}>
										                  <MenuList autoFocusItem={this.state.open} id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
										                    <a className="text-color-default" href={global.url+"/publikasi"} style={{textDecoration:"none"}} ><MenuItem className=" menu-dropdown" onClick={this.handleMenuClose}>{i18n.t('web_header_publikasi')}</MenuItem></a>
										                    <a className="text-color-default" href={global.url+"/gallery"} style={{textDecoration:"none"}} ><MenuItem className=" menu-dropdown" onClick={this.handleMenuClose}>{i18n.t('web_header_gallery')}</MenuItem></a>
										                    <a className="text-color-default" href={global.url+"/newsletter"} style={{textDecoration:"none"}} ><MenuItem className=" menu-dropdown" onClick={this.handleMenuClose}>NEWSLETTER</MenuItem></a>
										                  </MenuList>
										                </ClickAwayListener>
										              </Paper>
												</Grow>
											)
										}
									</Popper>
								</div>
								
							</Grid>
							<Grid container item md={3}  spacing={3} justify="flex-start" alignItems="center">
								<Grid item md={2}>
									<img src={process.env.PUBLIC_URL + "/images/language_u109.svg"} alt="" />
								</Grid>
								
								<Grid item md>
									<Link  className="link1 text-color-green" ref={this.anchorRef2} aria-controls={this.state.open2 ? 'managemen-menu' : undefined} aria-haspopup="true"  onClick={this.handleToggle2} style={{textDecoration:"none"}}><strong>{i18n.t('web_header_managemen')}</strong></Link>
									<Popper open={this.state.open2} anchorEl={this.anchorRef2.current} role={undefined} transition disablePortal>
										{
											({TransitionProps, placement}) => (
												<Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
													<Paper>
										                <ClickAwayListener onClickAway={this.handleMenuClose2}>
										                  <MenuList autoFocusItem={this.state.open2} id="menu-list-grow" onKeyDown={this.handleListKeyDown2}>
										                  	{	

										                  		this.state.data.map((item,index) => {
										                  			return (
										                  				<a className="text-color-default" href={item.url} target="_blank" style={{textDecoration:"none"}} >
										                  					<MenuItem className=" menu-dropdown" onClick={this.handleMenuClose2}>{item.name}</MenuItem>
										                  				</a>
										                  				
										                  			)
										                  		})
										                  	}
										                  	<a className="text-color-default" href={global.url+"/aplikasi-pktl"} style={{textDecoration:"none"}} ><MenuItem className=" menu-dropdown" onClick={this.handleMenuClose2}>APLIKASI PKTL</MenuItem></a>
										                    
										                  </MenuList>
										                </ClickAwayListener>
										              </Paper>
												</Grow>
											)
										}
									</Popper>
								</Grid>
								
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</div>
		);
	}


}

export default withTranslation()(Header);