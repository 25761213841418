import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';	
import {
	Link,
	Typography,
	Paper,
	Grid,
	Tabs,
	Tab,
	Icon,
	GridList,
	GridListTile,
	Box,
	Button,
	TextField,
	InputAdornment,
	Backdrop
} from '@material-ui/core' 
import IconButton from '@material-ui/core/IconButton';
import Pagination from '@material-ui/lab/Pagination';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import SearchIcon from '@material-ui/icons/Search';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { withTranslation } from 'react-i18next';
import i18n from './../i18n.js';


import './index.css';
import API from './../api.js';
import LanguageSelector from './../LanguageSelector.js'

import Cover from './Cover.jsx';
import ImageBackground from './../login_image.png';


const useStyles = {
	container: {
		minHeight: "100vh",
		paddingTop:"10vh",
		paddingBottom: "10vh",
		paddingLeft: "4vw",
		paddingRight: "7vw",
		"@media (min-width: 1444px)" : {
			paddingLeft: "0vh",
			paddingRight: "0vh",
			paddingBottom: "5vh",
			paddingTop:"5vh",
			minHeight: "50vh"
		}
		
		
	},
	cover: {
		background : `url(${ImageBackground}) no-repeat center center fixed`,
		backgroundSize: "cover",
		width:"100%",
		height: "60vh",

		color: "white",
		fontSize: "2em",
		"@media (max-width: 768px)" : {
			height:"25vh",
			fontSize: "1.2em",
		},
		"@media (max-width: 600px)" : {
			fontSize: "1.2em",
			height:"25vh"
		},
		"@media (min-width: 1444px)" : {
			fontSize: "2em",
			height:"30vh"
		}
	},
	footerOverlay: {
		bottom: 0,
		height: '100%',
		width: '100%',
		backgroundImage: 'linear-gradient(to bottom, rgba( 11, 69, 38,0), rgba( 11, 69, 38,0.5))'
	},
	footerContainer: {
		bottom: '25vh',
		left: 0,
		paddingLeft: '25vh',
		position: 'absolute',
		color: 'white',
		width:'80%',
		lineHeight: 0.2,
		"@media (max-width: 768px)" : {
			bottom:"48vh",
			paddingLeft: "10vw"
		},
		"@media (max-width: 600px)" : {
			bottom:"62vh",
			paddingLeft: "8vw"
		},
		"@media (min-width: 1444px)" : {
			bottom:"58vh",
			paddingLeft: "12vw"
		}
	},
	formSubmit: {
		backgroundColor: 'rgba(24, 195, 159, 1)',
		color: 'white',
		transition: 'ease',
		'&:hover': {
			backgroundColor: 'rgba(15, 157, 127, 1);'
		},
		"@media (max-width: 768px)" : {
			height: "3.5vh"
		},
		"@media (min-width: 1444px)" : {
			height: "3.5vh"
		}
	},
	searchInput: {
		backgroundColor:"white", 
		borderRadius:"10px", 
		width:"90%", 
		height:"5vh", 
		paddingLeft:"2vw",
		"@media (max-width: 768px)" : {
			height: "3vh"
		},
		"@media (max-width: 600px)" : {
			height: "3vh"
		},
		"@media (min-width: 1444px)" : {
			height: "3vh"
		}
	},
	selectCategory: {
		backgroundColor:"white", 
		borderRadius:"10px", 
		width:"100%", 
		height:"6vh", 
		paddingLeft:"2vw",
		"@media (max-width: 768px)" : {
			height: "3.5vh"
		},
		"@media (min-width: 1444px)" : {
			height: "3.5vh"
		}
	},
	viewMore: {
		width: '100%',
		height: "10vh"
	},
	divImage: {
		width: "100%",
		height: "auto",
		paddingLeft: "25%",

		
	},
	containerGallery: {
		paddingTop: "10%",
		paddingLeft: "10%",
		paddingRight: "10%",

		backgroundColor: "rgba(242, 242, 242, 1)",
		borderTopLeftRadius: "1em",
		borderTopRightRadius: "1em"
	},
	imageGallery: {
		width: "100%",
		height: "33vh",
		objectFit: "cover",
		"@media (max-width: 600px)" : {
			height: "20vh"
		},
		"@media (min-width: 1444px)" : {
			height: "20vh"
		}
	},
	textGallery: {
		paddingTop: "5vh",
		paddingLeft: "10%",
		paddingBottom: "10vh",
		"@media (min-width: 1444px)" : {
			paddingTop: "2vh",
			paddingBottom: "2vh"
		}
	},
	imageArrow: {
		cursor: "pointer",
		paddingLeft: "7vw",

		
	},
	postedOn: {
		marginTop: "5vh"
	},
	

	pagination: {
	    "& .Mui-selected": {
	      backgroundColor: "rgba(63, 183, 118, 1)",
	      borderColor : "rgba(63, 183, 118, 1)",
	      color: "white",
	      
	    },
	    "& .MuiPaginationItem-root": {
	    	"&:hover": {
		    	
		      	backgroundColor: "lightgray",
		      	borderColor : "lightgray",
		    }
	    }    
	},
	breadcrumbContainer: {
		marginTop:"5vh", 
		marginBottom:"5vh",
		"@media (max-width: 600px)" : {
			marginTop:"1vh", 
			marginBottom:"1vh",
		},
		"@media (min-width: 1444px)" : {
			marginTop:"3vh", 
			marginBottom:"3vh",
		}
	},
	
};

class PublikasiResult extends React.Component{

	constructor(props){
		super(props)
		this.state = {
			breadcrumb: "Publikasi",
			breadcrumb_ina: "Publikasi",
			breadcrumb_en : "Publication",
			data: [],
			page: 1,
			count: 1,
			limit : 5,
			filterCari: "",
			openBackdrop: false,
			backdropImage: "",
			backdropIndex: 0,
			backdropPrev: -1,
			backdropNext:0
		}

		this.handlePagination = this.handlePagination.bind(this)
		this.handleToggleBackdrop = this.handleToggleBackdrop.bind(this)
		this.handleCloseBackdrop = this.handleCloseBackdrop.bind(this)
		this.handlePrev = this.handlePrev.bind(this)
		this.handleNext = this.handleNext.bind(this)
		this.handleCategoryChange = this.handleCategoryChange.bind(this)
		this.handleSearchChange = this.handleSearchChange.bind(this)
		this.handleCari = this.handleCari.bind(this)
	

	}

	componentDidMount(){

		if(this.props.searchWord){
			this.setState({filterCari: this.props.searchWord})
		}
		
		API.get('/publikasi')
			.then(res=> {
				var apires = res.data.response;
				const data= [];
				const data_ina= [];
				const data_en= [];
				const count = Math.floor(apires.data.length / 9)
				apires.data.forEach( item => {

					if(item.name_ina == null){
						item.name_ina = ""
					}

					if(item.name_en == null){
						item.name_en = ""
					}

					if(item.description_ina == null){
						item.description_ina = ""
					}

					if(item.description_en == null){
						item.description_en = ""
					}

					data_ina.push({
						id: item.id,
						name: item.name_ina,
						category: item.category,
						description: item.description_ina,
						thumbnail: item.thumbnail,
						file: item.file_document,
						date: item.created_at.substr(0,10)
					})
					data_en.push({
						id: item.id,
						name: item.name_en,
						category: item.category,
						description: item.description_en,
						thumbnail: item.thumbnail,
						file: item.file_document,
						date: item.created_at.substr(0,10)
					})

					
				})

				
				this.setState({
					
					data: data_ina,
					data_ina: data_ina,
					data_en: data_en,
					count: count + 1
				})
			})
			.catch((error)=>{
				var err = error.response;
				
			})
	}

	handlePagination(e){
		this.setState({page:parseInt(e.target.textContent)})
		
	}

	handleToggleBackdrop(image_index){
		

		this.setState({openBackdrop: !this.state.openBackdrop, backdropImage: this.state.data[image_index].image, backdropIndex: image_index})

	}

	handleCloseBackdrop(){
		this.setState({openBackdrop: false})
	}

	handlePrev(){
		var curr = this.state.page
		if(curr == 1){
			var prev = curr
		}else{
			var prev = curr - 1
		}

		this.setState({ page: prev})
	}

	handleNext(){
		var curr = this.state.page
		if(curr == this.state.count){
			var next = curr
		}else{
			var next = curr + 1
		}

		this.setState({ page: next})
	}

	handleCategoryChange(e){
		this.setState({category: e.target.value})
	}	

	handleSearchChange(e){
		this.setState({filterCari: e.target.value})
	}

	handleCari(){
		var param1 = ""
		var param2 = ""

		if(this.state.category == ""){
			param1 = param1 + "/semua"
		}else{
			param1 = param1 + "/" + this.state.category
		}

		if(this.state.filterCari != ""){
			param2 = param2 + "/" +this.state.filterCari
		}

		window.location = global.url+'/publikasi-result'+param1+param2;
	}



	render(){ 

		const { classes } = this.props;


		if(this.props.kategori == "semua"){
			var filteredList = this.state.data.filter(x => this.state.filterCari == '' || (x.name && x.name.toLowerCase().includes(this.state.filterCari.toLowerCase())))
		}else{
			var filteredList = this.state.data.filter(x => this.props.kategori == '' || (x.category && x.category.toLowerCase().includes(this.props.kategori.toLowerCase())) ).filter(x => this.state.filterCari == '' || (x.name && x.name.toLowerCase().includes(this.state.filterCari.toLowerCase())))
		}

		
		
		return(
			<div>

				<Grid   className="breadcrumb-container"   >
				
					<Grid item md sm xs >
						<Breadcrumbs aria-label="breadcrumb">
						 	<a className="text-color-default" href={global.url+"/beranda"} style={{textDecoration:"none"}} >KalFor</a> | 
						 	<a className="text-color-default" href={global.url+"/publikasi"} style={{textDecoration:"none"}} >{this.state.breadcrumb}</a> | 
						 	<Typography className="text-color-green">Search Result</Typography>
						 </Breadcrumbs>
					</Grid>
				</Grid>
				
				<div>
					 <Grid container justify="flex-start" alignItems="center" className={classes.cover}>
					 	<div className={classes.footerOverlay}></div>
					 	<div className={classes.footerContainer}>
					 		<Grid item lg={1} md={1} xs={1} ></Grid>
						 	<Grid item lg={8} md={8} xs={12} >
						 		<h3 >Kalfor</h3>
						 		{
						 			window.innerWidth > 768 ? <h1 >{this.state.breadcrumb}</h1> : <h3 >{this.state.breadcrumb}</h3>
						 		}
						 		
						 		<Grid container spacing={2}>
						 			<Grid item lg={7} md={7} sm={7} >
						 				<input className={classes.searchInput} type="text" placeholder="Keyword Search..."  onChange={this.handleSearchChange} />
						 			</Grid>
						 			<Grid item lg={3} md={3} sm={3}>
						 				<select className={classes.selectCategory} value={this.state.value}  name="search" onChange={this.handleCategoryChange} >
						 					<option value="semua" defaultValue>Kategori</option>
						 					<option value="semua">Semua</option>
						 					<option value="buku_saku">Buku Saku</option>
											<option  value="factsheet"  >Factsheet</option>
											<option value="policy_brief" >Policy Brief</option>
										</select>
						 			</Grid>
						 			<Grid item lg={1} md={1} sm={1} >
						 				<Button className={classes.formSubmit} type="submit" style={{width:'100%'}} onClick={this.handleCari} >
								            <SearchIcon /> 
								        </Button>

						 			</Grid>
						 		</Grid>
						 	</Grid>
						 	
					 	</div>
					 </Grid>
				</div>
				<Grid container alignItems="center">
					<Grid item lg={1} md={1} sm={1} xs={1} >
					</Grid>
					<Grid item lg={10} md={10} sm={10} xs={10} >
						<Container className={classes.container}>
							<Grid container spacing={3}>
								{
									filteredList.slice((this.state.page - 1) * 9, (this.state.page * 9) ).map((item, index) => {

										return (
											<Grid item lg={4} md={4} sm={12} xs={12}  >
												<a className="text-color-default" href={global.url+"/publikasi-content/"+item.id} style={{textDecoration:"none"}}>
													<Paper elevation={5}  style={{ borderRadius:"1rem" }}>
													
														<div className={"container-gallery " + classes.containerGallery}>
															<Grid container >
																<img src={global.url_be_storage_public+"/"+ item.thumbnail} alt="" className={classes.imageGallery} />
															</Grid>
															
														</div>

														<Grid container> 
																<div className={classes.textGallery}>
																	<p className="text-color-default">
																		{item.date}
																	</p>
																	<h3>{item.name}</h3>
																</div>
																
														</Grid>
														
													</Paper>
												</a>
												
											</Grid>
										);

									})
								}
							</Grid>
							<Grid container justify="flex-end" style={{ marginTop:"5vh"}}>
								<Pagination className={classes.pagination} count={this.state.count} variant="outlined" shape="rounded" siblingCount={1} onChange={this.handlePagination}  />
							</Grid>
					
						</Container>
					</Grid>
					<Grid item lg={1} md={1} sm={1} xs={1}  >
						
					</Grid>
				</Grid>
				
				
			</div>
		)
	}


}


export default withTranslation()(withStyles(useStyles)(PublikasiResult));