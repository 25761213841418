import React, {Fragment}  from 'react';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
	Link,
	Typography,
	Paper,
	TextField,
	Grid,
	Tabs,
	Tab,
	Icon,
	GridList,
	GridListTile,
	Box,
	Button
} from '@material-ui/core' 
import { withStyles } from '@material-ui/core/styles';

import './index.css';

import API from './api.js';
import ImageBackground from './login_image.png';


const useStyles = {
	root: {
		background : `url(${ImageBackground}) no-repeat center center fixed`,
		backgroundSize: 'cover',
		marginBottom: '-20vh',
		padding: '0',
	},

	footerContainer: {
		bottom: '2vh',
		position: 'absolute',
		color: 'white',
		width:'100%',
	},
	footerOverlay: {
		position: 'absolute',
		bottom: '0',
		height: '100%',
		width: '100%',
		backgroundImage: 'linear-gradient(to bottom, rgba( 11, 69, 38,0), rgba( 11, 69, 38,1))'
	},

	formBox: {
		borderTopLeftRadius: '35%',
		height: '85vh',
		paddingTop: '15vh',
		paddingLeft: '15vh',
		paddingRight: '10vh',
		boxShadow: "0px 3px 100px -2px rgb(0 0 0 / 80%), 0px 3px 100px 0px rgb(0 0 0 / 50%), 0px 1px 100px 0px rgb(0 0 0 / 20%)"
	},

	formContainer: {
		marginTop : '10vh',
		width: '80%',
		left: '50%',
		paddingRight: '10%',
		paddingLeft: '10%'
		
	},
	
	formInput: {
		marginTop: '7vh'
	},

	formSubmit: {
		backgroundColor: 'rgba(15, 157, 127, 1)',
		color: 'white',
		transition: 'ease',
		'&:hover': {
			backgroundColor: 'rgba(24, 195, 159, 1);'
		}
	}
	
};

class Login extends React.Component{

	constructor(props){
		super(props);
		this.state = {
			formLogin: {
				email: "",
				password: ""
			}
		}

		this.handleChange = this.handleChange.bind(this);
		this.resetForm = this.resetForm.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

	}

	handleChange(e: React.ChangeEvent<HTMLInputElement>){
		var formState = this.state.[e.target.form.id]
		formState.[e.target.name] = e.target.value
		this.setState({formState})
	}

	resetForm(formId){
		var state_data = this.state.[formId];
		Object.keys(state_data).forEach(key => {
			state_data[key] = "";
		})
		this.setState({state_data})
		
	}

	handleSubmit(e){
		e.preventDefault();
		console.log("form submit data")
		console.log(this.state.[e.target.id])

		const formData = new FormData();
		var form_id = e.target.id;
		var form_action = e.target.action
		form_action = form_action.replace(global.root, "")
		var form_method = e.target.method
		var state_data = this.state.[e.target.id]

		Object.keys(state_data).forEach(key => {
			if(state_data[key] != ""){
				formData.append(key, state_data[key]);
			}
		});

		API({
			'headers': { 'Content-Type': 'application/x-www-form-urlencoded' },
			'method': 'POST',
			'url':  global.url_be+'/api'+form_action,
			'data': formData
		})
		.then(res => {
			var apires = res.data.response;
			console.log(apires);

			if(apires.code == 200){
				localStorage.setItem('token', apires.token)
				// alert(localStorage.getItem('token'))
				window.location = 'cms/beranda';
			}else{

				this.resetForm(form_id)
				alert(apires.message);

			}
		})
		.catch((error) => {
			console.log("error")
			var error_message = error.response.data.response.message
			alert(error_message)
		});
			
	}

	render(){
		const { classes } = this.props;
		return(
			<body className={classes.root} style={{overflow:'hidden'}}>
				
					<Grid container>
						<Grid item md={7} style={{position:"relative"}}>
						<div className={classes.footerOverlay}></div>
							<div className={classes.footerContainer}>
								<Grid container spacing={3}>
									<Grid item md={1}></Grid>
									<Grid item md={2}>
										<img src={process.env.PUBLIC_URL + "/images/login_page/u13.png"} alt="" style={{width:'100%'}} />
									</Grid>
									<Grid item md={7}>
										<h2 style={{marginTop:'0px'}}>Direktorat Jenderal Planologi Kehutanan dan Tata Lingkungan
										<br />Kementerian Lingkungan Hidup dan Kehutanan Republik Indonesia</h2>
										
									</Grid>
								</Grid>
								
							</div>
							
						</Grid>
						<Grid item md={5}>
							<Paper elevation={3} square className={classes.formBox}>
								<div>
									<img src={process.env.PUBLIC_URL + "/images/login_page/u15.png"} alt="" style={{width:'100%'}}/>
									<div  className={classes.formContainer}>
										<h3 style={{textAlign:'center'}}>Masuk dengan akun </h3>
										<form id="formLogin" action="/login"  method="POST" onSubmit={this.handleSubmit}>
											<div className={classes.formInput}> 
												<TextField name="email" label="Username / Email" InputLabelProps={{shrink: true}} style={{width:'100%'}}  onChange={this.handleChange}  />
											</div>
											<div className={classes.formInput}>
												<TextField name="password" label="Password" type="password" InputLabelProps={{shrink: true}} style={{width:'100%'}}   onChange={this.handleChange}   />
											</div>
											<div  className={classes.formInput}>
												<Button className={classes.formSubmit} type="submit" style={{width:'100%'}}>
										            Login
										        </Button>
											</div>
											
											
										</form>
									</div>
								</div>
							</Paper>
						</Grid>
					</Grid>
					
				
			</body>
		);
	}


}

export default withStyles(useStyles)(Login);