import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';	
import {
	Link,
	Typography,
	Paper,
	Grid,
	Tabs,
	Tab,
	Icon,
	GridList,
	GridListTile,
	Box,
	Button,
	TextField,
	InputAdornment
} from '@material-ui/core' 
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import SearchIcon from '@material-ui/icons/Search';
import { withTranslation } from 'react-i18next';
import i18n from './../i18n.js';


import './index.css';
import API from './../api.js';
import LanguageSelector from './../LanguageSelector.js'

import Cover from './Cover.jsx';


const useStyles ={
	container: {
		minHeight: "20vh",
		paddingTop:"2vh",
		paddingBottom: "10vh",
		
	},
	viewMore: {
		width: '100%',
		height: "10vh"
	},
	imageGallery: {
		height: "auto",
		marginBottom: "5vh"
	},
	postedOn: {
		marginTop: "5vh"
	}
	
	
};

class AktifitasContent extends React.Component{

	constructor(props){
		super(props)
		this.state = {
			breadcrumb: "Aktivitas Proyek",
			breadcrumb_ina: "Aktivitas Proyek",
			breadcrumb_en : "Project Activity",
			data: [],
			data_ina: [],
			data_en: [],
			limit : 5,
			filterCari: "",
		}

		this.handleLoadMore = this.handleLoadMore.bind(this)
		this.handleCari = this.handleCari.bind(this)
	}

	componentDidMount(){
		
		API.get('/aktifitas-proyek-id?id='+this.props.id)
			.then( res => {
				var apires = res.data.response;
				const data_ina = []
				const data_en = []
				apires.data.forEach( item => {

					if(item.name_ina == null){
						item.name_ina = ""
					}

					if(item.name_en == null){
						item.name_en = ""
					}

					if(item.description_ina == null){
						item.description_ina = ""
					}

					if(item.description_en == null){
						item.description_en = ""
					}
					
					data_ina.push({
						id: item.id,
						name: item.name_ina,
						description: item.description_ina,
						thumbnail: item.thumbnail,
						date: item.created_at.substr(0,10)
					})
					data_en.push({
						id: item.id,
						name: item.name_en,
						description: item.description_en,
						thumbnail: item.thumbnail,
						date: item.created_at.substr(0,10)
					})
				})

				this.setState({
					data: data_ina,
					data_ina: data_ina,
					data_en: data_en,
				})


			})
			.catch((error)=>{
				var err = error.response;

				
				
			})
	}

	static getDerivedStateFromProps(nextProps, prevState){

		if(nextProps.i18n.language == "ina"){
			return {
				data: prevState.data_ina,
				breadcrumb: prevState.breadcrumb_ina			
			}
		}else{
			return {
				data: prevState.data_en,
				breadcrumb: prevState.breadcrumb_en
			}
		}
	}

	handleCari(e){
		this.setState({filterCari: e.target.value})
	}

	handleLoadMore(){
		var data_length = this.state.data.length
		var remain_data = data_length - this.state.limit
		if(remain_data < 5){
			var load_more = remain_data
		}else{
			var load_more = 5;
		}

		this.setState({limit : this.state.limit + load_more})
	}


	render(){

		const { classes } = this.props;

		const filteredList = this.state.data.filter(x => this.state.filterCari == '' ||(x.name && x.name.toLowerCase().includes(this.state.filterCari.toLowerCase())))
		const cari = <TextField id="outlined-basic" label={ i18n.t('tabel_cari.label')} InputProps={{startAdornment: (<InputAdornment position="start"><SearchIcon /> </InputAdornment> )}} size="small" variant="outlined" onChange={this.handleCari} />


		return(
			<div>
				<Grid  className="breadcrumb-container"    >
					
					<Grid item md sm xs >
						<Breadcrumbs aria-label="breadcrumb">
						 	<a className="text-color-default" href={global.url+"/beranda"} style={{textDecoration:"none"}} >KalFor</a> | 
						 	<a className="text-color-default"  href={global.url+"/aktifitas"} style={{textDecoration:"none"}} >{this.state.breadcrumb}</a>
						 	<Typography className="text-color-green">{this.state.data[0] ? this.state.data[0].name.substr(0, 30) + (this.state.data[0].name.length > 30 ? "..." : "") : ""}</Typography>
						 </Breadcrumbs>
					</Grid>
				</Grid>
				
				<Grid className="container-content" >
					
					<Grid item md xs={12} justify="center" >
						<Container className={classes.container}>
								<h1 style={{textAlign:"center"}} >{this.state.data[0] ? this.state.data[0].name : ""}</h1>
								<div className="container-gallery" >
									<img src={global.url_be_storage_public+"/"+(this.state.data[0] ? this.state.data[0].thumbnail : "")} alt="" className={"image-gallery "+classes.imageGallery}  />
								</div>
								<div  dangerouslySetInnerHTML={{ __html: this.state.data[0] ? this.state.data[0].description : "" }} ></div>
								<div className={classes.postedOn}>Posted  on {this.state.data[0] ? this.state.data[0].date : ""}</div>
						</Container>
					</Grid>
				</Grid>
				
				
			</div>
		)
	}


}


export default withTranslation()(withStyles(useStyles)(AktifitasContent));