import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';


import HeaderComp from './Header.jsx';
import BodyComp from './Body.jsx';
import FooterComp from './Footer.jsx';
import WebLayout from './WebLayout.jsx';
import Aktifitas from './Aktifitas.jsx'
import AktifitasContent from './AktifitasContent.jsx';
import Fokus from './Fokus.jsx';
import Publikasi from './Publikasi.jsx'
import PublikasiResult from './PublikasiResult.jsx'
import PublikasiContent from './PublikasiContent.jsx'
import Newsletter from './Newsletter.jsx'
import NewsletterContent from './NewsletterContent.jsx'
import Gallery from './Gallery.jsx'
import GalleryContent from './GalleryContent.jsx'
import AplikasiPktl from './AplikasiPktl.jsx'
import Location from './Location.jsx'

class Web extends React.Component{
	render(){
		var msg = "Web Page";
		return ( 
			<Router basename="/" >
				<Switch>
					<Route path="/" exact>
						<WebLayout items={<BodyComp />} />
					</Route>
					<Route path="/beranda">
						<WebLayout items={<BodyComp />} />
					</Route>
					<Route path="/fokus">
						<WebLayout items={<Fokus  />} />
					</Route>
					<Route path="/aktifitas">
						<WebLayout items={<Aktifitas  />} />
					</Route>
					<Route path="/publikasi"> 
						<WebLayout items={<Publikasi  />} />
					</Route>
					<Route path="/newsletter"> 
						<WebLayout items={<Newsletter  />} />
					</Route>
					<Route path="/gallery"> 
						<WebLayout items={<Gallery  />} />
					</Route>
					<Route path="/aplikasi-pktl"> 
						<WebLayout items={<AplikasiPktl  />} />
					</Route>

					<Route path="/location">
						<WebLayout items={<Location  />} />
					</Route>

					{/*ADDITIONAL*/}
					<Route path="/aktifitas-content/:id" render={ ({match}) =>
						<WebLayout  items={<AktifitasContent id={match.params.id} />} activeBar="aktifitas" />
					} />
					<Route path="/newsletter-content/:id" render={ ({match}) =>
						<WebLayout  items={<NewsletterContent id={match.params.id} />} activeBar="newsletter" />
					} />
					<Route path="/gallery-content/:id" render={ ({match}) =>
						<WebLayout  items={<GalleryContent id={match.params.id} />} activeBar="galeri" />
					} />
					<Route path="/publikasi-content/:id" render={ ({match}) =>
						<WebLayout  items={<PublikasiContent id={match.params.id} />} activeBar="publikasi" />
					} />
					<Route path="/publikasi-result/:kategori?/:searchWord?" render={ ({match}) => 
						<WebLayout  items={<PublikasiResult kategori={match.params.kategori} searchWord={match.params.searchWord}  />} activeBar="publikasi" />
					
					} />


				</Switch>
			</Router>

		)
	}
	
}

export default Web;