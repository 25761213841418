import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';	
import {
	Link,
	Typography,
	Paper,
	Grid,
	Tabs,
	Tab,
	Icon,
	GridList,
	GridListTile,
	Box,
	Button,
	TextField,
	InputAdornment,
	Backdrop,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@material-ui/core' 
import IconButton from '@material-ui/core/IconButton';
import Pagination from '@material-ui/lab/Pagination';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SearchIcon from '@material-ui/icons/Search';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { withTranslation } from 'react-i18next';
import i18n from './../i18n.js';


import './index.css';
import API from './../api.js';
import LanguageSelector from './../LanguageSelector.js'

import Cover from './Cover.jsx';


const useStyles ={
	container: {
		minHeight: "100vh",
		paddingTop:"10vh",
		paddingBottom: "10vh",
		paddingLeft: "4vw",
		paddingRight: "7vw",
		"@media (max-width: 768px)" : {
			paddingBottom: "5vh"
		},
		"@media (max-width: 600px)" : {
			paddingLeft: "0vw",
			paddingRight: "0vw",
			paddingTop:"5vh",
		},
		"@media (min-width: 1444px)" : {
			paddingLeft: "2vh",
			paddingRight: "2vh",
			paddingBottom: "5vh",
			paddingTop:"5vh",
			minHeight: "50vh"
		}
		
		
	},
	viewMore: {
		width: '100%',
		height: "10vh"
	},
	divImage: {
		width: "100%",
		height: "auto",
		paddingLeft: "25%",

		
	},
	containerGallery: {
		paddingTop: "10%",
		paddingLeft: "10%",
		paddingRight: "10%",

		backgroundColor: "rgba(242, 242, 242, 1)",
		borderTopLeftRadius: "1em",
		borderTopRightRadius: "1em"
	},
	imageGallery: {
		width: "100%",
		
		objectFit: "cover",
		borderTopLeftRadius: "1em",
		borderTopRightRadius: "1em",
		
	},
	textGallery: {
		paddingTop: "5vh",
		paddingLeft: "10%",
		paddingBottom: "10vh",
		"@media (min-width: 1444px)" : {
			paddingTop: "0vh",
			paddingBottom: "0vh"
		}
	},
	buttonImg:{
		paddingTop:"5vh",
		paddingBottom:"7vh", 
		paddingLeft: "10%", 
		paddingRight:"10%",
		"@media (max-width: 600px)" : {
			paddingLeft: "12vw",
			paddingRight: "10vw",
			paddingTop:"5vh",
		},
		"@media (min-width: 1444px)" : {
			paddingTop:"3vh",
			paddingBottom:"5vh", 
			
		}
	},
	imageArrow: {
		cursor: "pointer",
		paddingLeft: "7vw",

		
	},
	postedOn: {
		marginTop: "5vh"
	},
	flipbook:{
		width:"60vw", 
		height: "80vh",
		"@media (max-width: 768px)" : {
			height:"40vh"
		},
		"@media (min-width: 1444px)" : {
			width:"60vw", 
			height: "80vh",
		}
	},
	flipbookIframe: {
		width: "100%", 
		height:"90%",
		"@media (min-width: 1444px)" : {
			width:"90%", 
			
		}

	},
	breadcrumbContainer: {
		marginTop:"5vh", 
		marginBottom:"5vh",
		"@media (max-width: 600px)" : {
			marginTop:"1vh", 
			marginBottom:"1vh",
		},
		"@media (min-width: 1444px)" : {
			marginTop:"3vh", 
			marginBottom:"3vh",
		}
	},
	
	
};

class PublikasiContent extends React.Component{

	constructor(props){
		super(props)
		this.state = {
			breadcrumb: "Publikasi",
			breadcrumb_ina: "Publikasi",
			breadcrumb_en : "Publication",
			data: [],
			page: 1,
			count: 1,
			limit : 5,
			filterCari: "",
			openBackdrop: false,
			backdropImage: "",
			backdropIndex: 0,
			backdropPrev: -1,
			backdropNext:0,
			open_pdf: false,
		}

		this.handlePagination = this.handlePagination.bind(this)
		this.handleToggleBackdrop = this.handleToggleBackdrop.bind(this)
		this.handleCloseBackdrop = this.handleCloseBackdrop.bind(this)
		this.handlePrev = this.handlePrev.bind(this)
		this.handleNext = this.handleNext.bind(this)
		this.handleOpenPdf = this.handleOpenPdf.bind(this)
		this.closeDialog = this.closeDialog.bind(this)
	}

	componentDidMount(){
		
		API.get('/publikasi-id?id='+this.props.id)
			.then(res=> {
				var apires = res.data.response;
				const data= [];
				const data_ina= [];
				const data_en= [];
				
				apires.data.forEach( item => {

					if(item.name_ina == null){
						item.name_ina = ""
					}

					if(item.name_en == null){
						item.name_en = ""
					}

					if(item.description_ina == null){
						item.description_ina = ""
					}

					if(item.description_en == null){
						item.description_en = ""
					}
					
					data_ina.push({
						id: item.id,
						name: item.name_ina,
						description: item.description_ina,
						thumbnail: item.thumbnail,
						file: item.file_document,
						date: item.created_at.substr(0,10)
					})
					data_en.push({
						id: item.id,
						name: item.name_en,
						description: item.description_en,
						thumbnail: item.thumbnail,
						file: item.file_document,
						date: item.created_at.substr(0,10)
					})

					
				})

				
				this.setState({
					
					data: data_ina,
					data_ina: data_ina,
					data_en: data_en,
					
				})
			})
			.catch((error)=>{
				var err = error.response;
	
				
				
			})
	}

	handlePagination(e){
		this.setState({page:parseInt(e.target.textContent)})
		
	}

	handleToggleBackdrop(image_index){
		

		this.setState({openBackdrop: !this.state.openBackdrop, backdropImage: this.state.data[image_index].image, backdropIndex: image_index})

	}

	handleCloseBackdrop(){
		this.setState({openBackdrop: false})
	}

	handlePrev(){
		var curr = this.state.page
		if(curr == 1){
			var prev = curr
		}else{
			var prev = curr - 1
		}

		this.setState({ page: prev})
	}

	handleNext(){
		var curr = this.state.page
		if(curr == this.state.count){
			var next = curr
		}else{
			var next = curr + 1
		}

		this.setState({ page: next})
	}

	handleOpenPdf(){
		this.setState({ open_pdf: true})
	}

	closeDialog(e){
		this.setState({open_pdf:false})
	}


	render(){

		const { classes } = this.props;

		
		return(
			<div>

				<Grid   className="breadcrumb-container"   >
					<Grid item md={1} sm={1} xs={1} >
						
					</Grid>
					<Grid item md sm >
						<Breadcrumbs aria-label="breadcrumb">
						 	<a className="text-color-default" href={global.url+"/beranda"} style={{textDecoration:"none"}} >KalFor</a> | 
						 	<a className="text-color-default"  href={global.url+"/publikasi"} style={{textDecoration:"none"}} >{this.state.breadcrumb}</a>
						 	<Typography className="text-color-green">{this.state.data[0] ? this.state.data[0].name.substr(0, 30) + (this.state.data[0].name.length > 30 ? "..." : "") : ""}</Typography>
						 </Breadcrumbs>
					</Grid>
				</Grid>
				
				{/*<Cover menu_name={this.state.breadcrumb} />*/}
				<Grid container alignItems="center">
					<Grid item lg={1} md={1} sm={1} xs={1} >
					</Grid>
					<Grid item lg={10} md={10} sm={10} xs={10} >
						<Container className={classes.container}>
							<Grid container spacing={5} >
								
								{
									this.state.data.slice((this.state.page - 1) * 3, (this.state.page * 3) ).map((item, index) => {

										return (
											<Grid item lg={6} md={6} xs={12}>
												
													<Paper elevation={5}  style={{ borderRadius:"1rem" }}>
													
														<div className={"container-gallery " + classes.containerGallery}>
															<Grid container >
																<img src={global.url_be_storage_public+"/"+ item.thumbnail} alt="" className={classes.imageGallery} />
															</Grid>
															
														</div>
														<div className={"div-center "+classes.buttonImg}  >
											        	 	<Grid container spacing={1} alignItems="center">
											        	 		<Grid item lg={6} md={6}>
											        	 			<a href={global.url_be_storage_public+"/"+item.file} target="_blank" style={{textDecoration:"none"}} >
												        	 			<Button id="modal-submit" size="submit" fontsize="large">
																        Download
																        </Button>
																    </a>
											        	 		</Grid>
											        	 		<Grid item lg md>
												        	 			<Button id="modal-submit" size="large" onClick={this.handleOpenPdf}>
																           <VisibilityIcon fontsize="large" /> &nbsp;&nbsp; View
																        </Button>
											        	 		</Grid>
											        	 	</Grid>
										  				</div>		
													
														
													</Paper>
												
											</Grid>
										);

									})
								}
								<Grid item lg={6} md={6}>
									<h1 style={{textAlign:"justify"}} >{this.state.data[0] ? this.state.data[0].name : ""}</h1>
									
									<div  dangerouslySetInnerHTML={{ __html: this.state.data[0] ? this.state.data[0].description : "" }} ></div>
									
								</Grid>
							</Grid>
							
					
						</Container>
					</Grid>
					<Grid item lg={1} md={1} sm={1} >
						
					</Grid>
				</Grid>
				
				<Dialog className="dialog-custom" maxWidth="md" open={this.state.open_pdf} onClose={this.closeDialog} aria-labelledby="form-dialog-title"  >
						
			        <DialogContent>
			  
			        	<div className={classes.flipbook}  >
			        		<iframe src={global.url_be+"/pdf-flipbook?pdf="+(this.state.data[0] ? this.state.data[0].file : "")} className={classes.flipbookIframe} ></iframe>
			        	</div>
					        

			        </DialogContent>
			        
			      </Dialog>
					
			</div>
		)
	}


}


export default withTranslation()(withStyles(useStyles)(PublikasiContent));