import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';	
import {
	Link,
	Typography,
	Paper,
	Grid,
	Tabs,
	Tab,
	Icon,
	GridList,
	GridListTile,
	Box,
	Button,
	TextField,
	InputAdornment
} from '@material-ui/core' 
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import SearchIcon from '@material-ui/icons/Search';
import { withTranslation } from 'react-i18next';
import i18n from './../i18n.js';


import './index.css';
import API from './../api.js';
import LanguageSelector from './../LanguageSelector.js'

import Cover from './Cover.jsx';


const useStyles = {
	container: {
		minHeight: "20vh",
		paddingTop:"5vh",
		paddingBottom: "10vh",
		paddingLeft: "20vh",
		paddingRight: "27vh",
		"@media (max-width: 768px)" : {
			paddingLeft: "5vh",
			paddingRight: "5vh"
		},
		"@media (max-width: 600px)" : {
			paddingLeft: "8vw",
			paddingRight: "8vw"
		},
		"@media (min-width: 1444px)" : {
			paddingLeft: "0vh",
			paddingRight: "0vh",
			paddingBottom: "2vh"
		}
	},
	viewMore: {
		width: '100%',
		height: "10vh",
		"@media (min-width: 1444px)" : {
			height: "6vh"
		}
	},
	cari: {
		marginBottom: "10vh",
		"@media (max-width: 768px)" : {
			marginBottom: "5vh",
		},
		"@media (max-width: 600px)" : {
			width: "100%"
		},
		"@media (min-width: 1444px)" : {
			marginBottom: "3vh",
		}
	}
};

class Aktifitas extends React.Component{

	constructor(props){
		super(props)
		this.state = {
			breadcrumb: "Aktivitas Proyek",
			breadcrumb_ina: "Aktivitas Proyek",
			breadcrumb_en : "Project Activity",
			data: [],
			data_ina: [],
			data_en: [],
			limit : 5,
			filterCari: "",
		}

		this.handleLoadMore = this.handleLoadMore.bind(this)
		this.handleCari = this.handleCari.bind(this)
	}

	componentDidMount(){
		
		API.get('/aktifitas-proyek')
			.then( res => {
				var apires = res.data.response;
				const data_ina = []
				const data_en = []
				apires.data.forEach( item => {
					
					if(item.name_ina == null || item.name_ina == "null"){
						item.name_ina = ""
					}

					if(item.name_en == null || item.name_en == "null"){
						item.name_en = ""
					}
					
					if(item.description_ina == null || item.description_ina == "null"){
						item.description_ina = ""
					}

					if(item.description_en == null || item.description_en == "null"){
						item.description_en = ""
					}

					data_ina.push({
						id: item.id,
						name: item.name_ina,
						description: item.description_ina,
						thumbnail: item.thumbnail,
						date: item.created_at.substr(0,10)
					})
					data_en.push({
						id: item.id,
						name: item.name_en,
						description: item.description_en,
						thumbnail: item.thumbnail,
						date: item.created_at.substr(0,10)
					})
				})

				this.setState({
					data: data_ina,
					data_ina: data_ina,
					data_en: data_en,

				})
			})
			.catch((error)=>{
				var err = error.response;
		
				
				
			})
	}

	static getDerivedStateFromProps(nextProps, prevState){

		if(nextProps.i18n.language == "ina"){
			return {
				data: prevState.data_ina,
				breadcrumb: prevState.breadcrumb_ina			
			}
		}else{
			return {
				data: prevState.data_en,
				breadcrumb: prevState.breadcrumb_en
			}
		}
	}

	handleCari(e){
		this.setState({filterCari: e.target.value})
	}

	handleLoadMore(){
		var data_length = this.state.data.length
		var remain_data = data_length - this.state.limit
		if(remain_data < 5){
			var load_more = remain_data
		}else{
			var load_more = 5;
		}

		this.setState({limit : this.state.limit + load_more})
	}


	render(){

		const { classes } = this.props;

		const filteredList = this.state.data.filter(x => this.state.filterCari == '' ||(x.name && x.name.toLowerCase().includes(this.state.filterCari.toLowerCase())))
		const cari = <TextField id="outlined-basic" label={ i18n.t('tabel_cari.label')} InputProps={{startAdornment: (<InputAdornment position="start"><SearchIcon /> </InputAdornment> )}} size="small" variant="outlined" onChange={this.handleCari} />


		return(
			<div>
				<Grid  className="breadcrumb-container"   >
					
					<Grid item md  sm xs >
						<Breadcrumbs aria-label="breadcrumb">
						 	<a className="text-color-default" href={global.url+"/beranda"} style={{textDecoration:"none"}} >KalFor</a> | 
						 	<Typography className="text-color-green">{this.state.breadcrumb}</Typography>
						 </Breadcrumbs>
					</Grid>
				</Grid>
				
				<Cover menu_name={this.state.breadcrumb} />
				<Container className={classes.container}>
						<Grid className={classes.cari} container justify="flex-end" >
							{cari}
						</Grid>
						{
							filteredList.slice(0, this.state.limit).map((item, index, arr)=> {

								if(arr.length - 1 != index || this.state.limit >= this.state.data.length){
									var more = ""
								}else{
									var more = <Button color="default" variant="contained" className={classes.viewMore} onClick={this.handleLoadMore} >View More</Button>
								}
								return(
									<Grid container spacing={3} style={{marginBottom:"5vh"}}>
										<Grid item lg={4} md={4} sm={12} xs={12} >
											<div className="container-gallery">
												<a href={global.url+"/aktifitas-content/" + item.id}>
													<img src={global.url_be_storage_public+"/"+item.thumbnail} alt="" className="image-gallery" />
												</a>
												
												
											</div>
										</Grid>
										<Grid item lg={8} md={8} sm={12} xs={12} styles={{height:"1em", textOverflow:"ellipsis", wordWrap:"break-word" }} >
											<h3 style={{ marginTop:"-0.5vh"}} >{item.name}</h3>
											{
												window.innerWidth > 768 ?
												<div>
													<div  dangerouslySetInnerHTML={{ __html: item.description.substr(0, 500) }} ></div>
														<div>Posted  on {item.date}</div>
												</div> 
												: null
											}

											
										</Grid>
										
											{more}
										
									</Grid>
								)
							})
						}
						
					
				</Container>
			</div>
		)
	}


}


export default withTranslation()(withStyles(useStyles)(Aktifitas));