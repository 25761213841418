import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
	Link,
	Typography,
	Paper,
	Grid,
	Tabs,
	Tab,
	Icon,
	GridList,
	GridListTile,
	Box,
	Button
} from '@material-ui/core' 

import { withTranslation } from 'react-i18next';
import i18n from './../i18n.js';

import './index.css';
import API from './../api.js';
import LanguageSelector from './../LanguageSelector.js'


class Footer extends React.Component{

	constructor(props){
		super(props);
		this.state = {
			data: [],
			address: ""
		}

		this.handleAddress = this.handleAddress.bind(this)
	}

	componentDidMount(){
		API.get('/beranda')
			.then( res => {
				var apires = res.data.response;
				const data = {
					instagram: apires.footer.instagram ,
					youtube: apires.footer.youtube ,
					twitter: apires.footer.twitter ,
					jakarta_office: apires.footer.jakarta_office ,
					pontianak_office: apires.footer.pontianak_office ,
					palangkaraya_office: apires.footer.palangkaraya_office ,
					samarinda_office: apires.footer.samarinda_office ,
					
				}


				
				this.setState({
					data: data,
					address: apires.footer.jakarta_office
					
				})

		
			})
	}

	handleAddress(e){

		this.setState({address: e.target.attributes.value.value})


	}


	render(){

		return(

			<div class="footer">
				<Grid container style={{marginBottom:"1.5vh"}}>
					<Grid item md={5} sm={5} xs={5} className="footer-left text-color-white">
						<img src={process.env.PUBLIC_URL + "/images/u87.png"} alt="" style={{width:"25vw"}}/>
						<div style={{whiteSpace:"nowrap"}}>
							<h3>Follow Us</h3>
							<Grid container>
								<Grid item md={3} sm={3} xs={3} >
									
								</Grid>
								<Grid item md={3} sm={3} xs={3} >
									<a href={this.state.data.instagram} target="_blank">
										<img className="footer-logo" src={process.env.PUBLIC_URL + "/images/u88.svg"} alt=""/>
									</a>
									
								</Grid>
								<Grid item md={3} sm={3} xs={3} >
									<a href={this.state.data.twitter} target="_blank">
										<img className="footer-logo"  src={process.env.PUBLIC_URL + "/images/u90.svg"} alt="" />
									</a>
									
								</Grid>
								<Grid item md={3} sm={3} xs={3} >
									<a href={this.state.data.youtube} target="_blank">
										<img className="footer-logo"  src={process.env.PUBLIC_URL + "/images/u89.svg"} alt="" />
									</a>
									
								</Grid>
							</Grid>
						</div>
						<div>
							<p>© 2021 Kalimantan Forest Project</p>
						</div>
					</Grid>
					 
						<div className="vertical-line"></div>
					
					<Grid item md={5} sm={5} xs={5} className="footer-right text-color-white">
						<Grid container  justify="center" spacing={3}>
							<Grid item md={3} sm={3} xs={3} >
								<Link  className="header-menu-item" value={this.state.data.jakarta_office} onClick={this.handleAddress}>Jakarta</Link>
							</Grid>
							<Grid item md={3} sm={3} xs={3} >
								<Link className="header-menu-item" value={this.state.data.pontianak_office} onClick={this.handleAddress}>Kalbar</Link>
							</Grid>
							<Grid item md={3} sm={3} xs={3}  >
								<Link  className="header-menu-item" value={this.state.data.palangkaraya_office} onClick={this.handleAddress}>Kalteng</Link>
							</Grid>
							<Grid item md={3} sm={3} xs={3} >
								<Link className="header-menu-item" value={this.state.data.samarinda_office} onClick={this.handleAddress}>Kaltim</Link>
							</Grid>
						</Grid>
						<div className="footer-address"  dangerouslySetInnerHTML={{ __html: this.state.address }} ></div>
						
					</Grid>
				</Grid>
			</div>

		);

	}

}


export default withTranslation()(Footer);