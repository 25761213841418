import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';
import Login from './login.jsx';
import Web from './web/web.jsx';
// import Cms from './cms/cms.jsx';



class App extends React.Component {
  render(){
    return (
        <Web />        
    )
  }
}

export default App;
