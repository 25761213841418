import axios from 'axios';

export default axios.create({
	// baseURL : 'http://localhost:8000/api/',
	baseURL: 'https://ipsdh.menlhk.go.id/be/api/',
	headers: {
		"Access-Control-Allow-Origin" : "*",
        crossDomain: true,
		Authorization: `Bearer ${localStorage.getItem('token')}`
	}
});